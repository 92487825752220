<!-- <template>
  <ion-item>
    <ion-label position="stacked" color='secondary' class="ion-text-wrap"><slot /></ion-label>
    <ion-textarea auto-grow autofocus :rows="props.rows"
      :value="props.modelValue"
      @ionInput="$emit('update:modelValue', $event.target.value)">
    </ion-textarea>
  </ion-item>
</template>

<script setup>
  import {IonItem, IonLabel, IonTextarea} from "@ionic/vue";
  const props = defineProps(["modelValue","rows",]);
</script> -->

<template>
  <div class="input-container">
    <ion-item class="chat-item" lines="none">
      <ion-textarea auto-grow autofocus class="chat-input"
        :rows="defaultRows" :placeholder="props.placeholder" :value="props.modelValue"
        :disabled="isDisabled"
        @ionInput="$emit('update:modelValue', $event.target.value)">
      </ion-textarea>
      <ion-button fill="clear" class="send-icon" @click="$emit('click_submit')">
        <ion-icon :icon="paperPlane"></ion-icon>
      </ion-button>
    </ion-item>
  </div>
</template>


<script setup>
import { computed } from 'vue';
import { IonItem, IonTextarea, IonIcon, IonButton } from "@ionic/vue";
import { paperPlane } from 'ionicons/icons';

const props = defineProps(["modelValue","rows","placeholder","disabled"]);

// Set the default value of the rows property to 1
const defaultRows = computed(() => { return props.rows || 1; });

const isDisabled = computed(() => { return props.disabled || false; });

</script>

<style scoped>
.input-container {
  display: flex;
  align-items: center;
  /*padding: 10px;*/
  margin: 1em;
}

.chat-item {
  --border-radius: 0.5em;
  --background: #f2f2f2;
  --padding-start: 10px;
  --padding-end: 0;
  margin: 0;
  width: 100%;
  display: flex; 
  flex-direction: column; 
}

.chat-input {
  --background: #f2f2f2;
  /* border: unset;
  outline: none;  */
  padding: 0 5px;
  margin: 0;
  resize: none; 
}

.send-icon {
  font-size: 16px;
  cursor: pointer;
  color: var(--ion-color-secondary-shade);
  margin: auto -10px 10px -10px;
  padding: 0;
  
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

</style>
