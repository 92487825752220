import {createApp} from 'vue'
import App from './App.vue'
import BaseLayout from './components/BaseLayout.vue';

//let language = 'en';
//import router from './router/index_en.js'
let language = 'zh';
import router from './router';


// localization
import {messages} from '@/i18n/messages.js'
import {createI18n} from 'vue-i18n';

import {IonicVue} from '@ionic/vue';
/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/core.css';

import {web_url} from '@/utils';

import {defineCustomElements} from '@ionic/pwa-elements/loader';

defineCustomElements(window);


//注意:code是解析url的#hash得到的，页面ready后，可能就变了。只能在这里获取
console.log("first open:" + document.location.href);
web_url.value = document.location.href;
//console.log("web_url = " + web_url.value);

//微信小程序通过url传递recm和openid
let recm = '';
let t = document.location.href.split('recm=', 2);
if (t.length > 1) {
    recm = t[1].split(/&|#/, 2)[0];
}

let code = '';
t = document.location.href.split('#', 2);
if (t.length > 1) {
    code = t[1];
}

//save code and recm parameters, used in ChatPage to login
console.log("set recm:", recm);
console.log("set code:", code);
window._wcprms = {code, recm};

// localization
const i18n = createI18n({
    allowComposition: true,
    globalInjection: true,
    locale: language,
    fallbackLocale: language,
    legacy: false,
    messages
})

const app = createApp(App).use(IonicVue).use(router).use(i18n);

app.component('base-layout', BaseLayout);

router.isReady().then(() => {
    app.mount('#app');
});

