<template>
  <base-layout :page-title=chatpage_title>
    <template v-slot:actions-end v-if="session?.user">
      <ion-button router-link="/review" v-if="debug">
        <ion-icon slot="icon-only" :icon="chevronBackCircle"></ion-icon>
      </ion-button>
    </template>

    <div v-if="isLoading">
      <LoadingScreen></LoadingScreen>
    </div>
    <div v-else>
      <div v-if="!session?.user?.id" class="ion-padding ion-text-center">
        <p>
          <ion-label color="primary">
        <h1>Please <a href=/>sign in</a> first.</h1></ion-label></p>
      </div>
      <div v-else>
        <div id='div_greeting' v-if="show_greeting">
          <div v-if="!new_user">
            <ChatMessage class='hidden'>{{ user_alias }}，{{t('chat_see_you_again')}}</ChatMessage>
          </div>
          <div v-else id='div_first_time'>
            <div id="div_ask_alias">
              <div><img src="/assets/hello.png"></div>
              <ChatInputArea v-model="user_alias" placeholder="请输入你的名字..."
                             @click_submit="submit_my_username()">
              </ChatInputArea>
            </div>
          </div>
          <div v-if="show_charge_info" id='div_charge_info'>
            <ChatMessage v-if='!new_user' class='hidden'>{{t('chat_if_suicide_thought')}}
              <router-link :to="{name:'HotLine'}">{{t('chat_click_here')}}</router-link>
              。
            </ChatMessage>
            <ChatMessage class='hidden'>温馨提示：<br/>
              * 好的体验来自真实的内心需求，前两次体验的鹿角由长颈鹿提供，请珍惜哦。<br/>
              * 与长颈鹿耳朵的对话为您全程保密。自我开放度越高，效果越好。<br/>
            </ChatMessage>
            <ChatMessage class='hidden'>
              <b>如果现在没有强烈的烦恼，只是好奇，想了解机器人如何做心理咨询，请先看
                <a href="https://mp.weixin.qq.com/s/3A3bBDIARsKwKOVU8Y-EqQ" target="_blank">这个体验示例</a>
              </b>
            </ChatMessage>
            <ChatMessage v-if='session.user.need_money && session.user.need_money>0' class='hidden'>目前你的鹿角不够，请
              <router-link :to="{path:'/recharge'}">
                <b>充值 {{ session.user.need_money || '' }} 元</b></router-link>以上，才能继续
            </ChatMessage>
            <div class="center hidden">
              <ion-button shape="round" @click="chat_greeting" class="navBtn">OK</ion-button>
            </div>
          </div> 
          <div v-if="show_chat_start" id="div_chat_start">
            <!-- <ChatMessage class='hidden'>谢谢你选择了我，我就在这里，愿意随时倾听你的心事。</ChatMessage>  -->
            <ChatMessage class='hidden'>{{t('chat_if_suicide_thought')}}
              <router-link :to="{name:'HotLine'}">{{t('chat_click_here')}}
              </router-link>
              。
            </ChatMessage>
            <ChatMessage class='hidden'>我用人本主义和认知行为等心理咨询方法，促进你更多地理解和懂得自己，疏解各种日常心理烦恼，包括婚恋、家庭、夫妻、育儿、职场、学业、个人的问题，各种心情困扰。</ChatMessage>
            <ChatMessage class='hidden'>谈话内容为您保密。</ChatMessage>
            <ChatMessage class='hidden'>一次访谈时间约 40-60 分钟，包括4到8个模块（根据不同情况需要）。</ChatMessage>
            <ChatMessage class='hidden'>在一个安静的个人空间，慢下来思考和体验，才会有帮助。</ChatMessage>
            <!-- <ChatMessage class='hidden'>在这里，<b>你是自由的，你的感受是最重要的。</b></ChatMessage> -->
            <!-- <ChatMessage class='hidden'>有问题或建议，请切换到下方的“反馈”页面再切换回来。</ChatMessage>-->
            <!-- <ChatMessage class='hidden'>从现在开始，由我来倾听了解你的烦恼。</ChatMessage>-->
            <div class="center hidden">
              <ion-button shape="round" @click="start_chat_ai" class="navBtn">我有一个小时，做一次访谈吧</ion-button>
            </div>
            <!-- <ion-button color="secondary" fill="clear" @click="start_chat_ai" class="center hidden btnStart" >
              <ion-icon :icon="chevronForwardCircle" />开始
            </ion-button>
            <div v-if="debug">
              <ion-button color="secondary" fill="clear" @click="start_chat" class="center hidden" >
                <ion-icon :icon="chevronForwardCircle" />旧版
              </ion-button>
            </div> -->
          </div>
        </div>

        <Transition>
          <div v-if="show_ai_block" id="div_ai">
            <div id="ai_warmup">
              <ChatMessage class='hidden'>谢谢你让我对你有更多的了解。</ChatMessage>
              <ChatMessage class='hidden'>我们一起试着梳理你的思绪。</ChatMessage>
            </div>
            <div id="div_ai_choice">
              <div class='choiceGroup' v-if="show_ai_result">
                <ChatMessage v-if="leaf_choices.length > 1">你最想讨论的，属于下列话题中的哪一个呢？</ChatMessage>
                <ChatMessage v-else>你想讨论的，是不是：</ChatMessage>
                <div v-for="(choice, i) in leaf_choices" :key="i" @click="click_ai_choice(choice)">
                  <ChatChoice>{{ choice }}</ChatChoice>
                </div>
                <div @click="click_ai_choice(-1)">
                  <ChatChoice v-if="leaf_choices.length > 1">以上话题都不相关</ChatChoice>
                  <ChatChoice v-else>不是</ChatChoice>
                </div>
              </div>
              <div v-if="ai_weak">
                <ChatMessage v-if="!leaf_choices.length">{{ user_alias }}，整个情境中最让你困扰的是？</ChatMessage>
                <ChatInputArea v-model="ai_weak_topic" :placeholder="ai_weak_placeholder"
                               @click_submit="submit_ai_weak()">
                </ChatInputArea>
              </div>
            </div>
            <!-- <div id="ai_choice_msg" class="hidden">
              <ChatMessage>{{user_alias}}, 谢谢你告诉我，你想讨论的是：{{ ai_weak_topic }}</ChatMessage>
            </div> -->
          </div>
        </Transition>

        <Transition>
          <div v-if="show_tree_node_block" id="div_tree" class='transition'>
            <ChatMessage>{{ user_alias }}, {{ node_selection_question }}</ChatMessage>
            <div class='choiceGroup' id="tree_choice">
              <div v-for="choice in choices" :key="choice.id" @click="click_tree_node(choice.id)">
                <ChatChoice>{{ choice.choice }}</ChatChoice>
              </div>
              <ion-button v-if="parent_id >= 0" color="secondary" fill="clear" class='center'
                          @click="click_tree_node(parent_id)">
                <ion-icon :icon="chevronBackCircle"/>
                返回
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_start_score_block" id="div_start_score">
            <ChatMessage>如果给你的烦恼打个分（0-10）</ChatMessage>
            <ChatMessage>零分最平静，十分最强烈，你会打几分？</ChatMessage>
            <div class='choiceGroup'>
              <div v-for="i in 11" :key="i" @click="click_score(i, 0)">
                <ChatChoice>{{ i-1 }} </ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_details_input_block" id="div_input">
            <div v-if="ai_more_input">  <!-- 树洞环节 -->
              <div id="messagesWrapper">
                <ChatMessage v-for="(message, i) in chat_messages" :key="i" class="chat-message"
                             :fromUser="i % 2 == 1"> {{ message }}   <!-- odd/even from user/machine-->
                </ChatMessage>   <!-- 来回对话的树洞设置 -->
              </div>
             <div id="div_details" class="bottom-input">  <!-- 可供用户点击的现成回复句/跳到感受 -->
                <ion-button v-if="ai_detail_no && g_user_chat_history.details_input && g_user_chat_history.details_input.length > 60" fill="clear" style="margin-bottom: 10px;"
                            @click="ai_more_details(1)">  <!-- 如果用户点击结束板块，则end=1，跳到感受 -->
                  <ion-icon :icon="chevronForwardCircle"/>
                  {{ ai_detail_no }}
                </ion-button> <!-- 读取用户输入，生成机器回复： -->
                 <!-- 
                <ChatInputArea v-model="user_input_details" :placeholder="input_prompt" :disabled='disableInput'
                               @click_submit="finish_the_details()"></ChatInputArea>  -->  
                <!-- ChatInputArea where users type, with scroll on focus 解决键盘遮住打字内容的问题-->             
                <ChatInputArea v-model="user_input_details" :placeholder="input_prompt" :disabled="disableInput"
                                @click_submit="finish_the_details()" 
                                @focus="$event.target.scrollIntoView({ behavior: 'smooth', block: 'center' })"> </ChatInputArea>                                
              </div>
            </div>
            <div v-else id="div_branch_choice">
              <!-- 
              <ChatMessage><span id="span_question"><span></span>{{ user_input_question }}</span>
              </ChatMessage>
              <ChatMessage v-if="ai_require_input" id="div_ai_require">我很想更有效地倾听、了解你，请再说一点。
              </ChatMessage>
              <ChatInputArea v-model="user_input_details" :placeholder="input_prompt"
                             @click_submit="finish_the_details()"></ChatInputArea> -->
              <ChatMessage>你最想讨论的，属于下列话题中的哪一类呢？</ChatMessage>
              <div v-for="(choice, i) in branch_choices" :key="i" @click="click_branch_choice(choice)">
                <ChatChoice class="chat-choice">
                  <div class="content">
                    <div class="choice">{{ choice }}</div>
                    <div class="detail">{{ branch_detail_choices[i] }}</div>
                  </div>
                </ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feelings_block" id="div_feeling">
            <ChatMessage><span v-if="first_feeling">{{ user_alias }}，{{ first_feeling_prompt }}</span>
              <span v-else>嗯。</span>你有没有觉得<b>{{ feeling_curr }}</b>?
            </ChatMessage>
            <div class='choiceGroup'>
              <div @click="click_feeling('yes')">
                <ChatChoice>是的, 我觉得{{ feeling_curr }}</ChatChoice>
              </div>
              <div @click="click_feeling('no')">
                <ChatChoice>还好, 没觉得{{ feeling_curr }}</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_detail_block" id="div_feeling_detail">
            <ChatMessage>{{ feeling_detail_question }}
              <router-link :to="{name:'FeelingDetail'}"><b>示例</b></router-link>
            </ChatMessage>
            <ChatInputArea v-model="feeling_detail" @click_submit="submit_feeling_detail()">
            </ChatInputArea>
            <ion-button fill="clear" class="ion-float-right endChoice"
                        @click="submit_feeling_detail()">
              <ion-icon :icon="chevronForwardCircle"/>
              暂时没有要说的了
            </ion-button>
            <ion-button fill="clear" class="ion-float-left endChoice" router-link="/review">
              <ion-icon :icon="chevronBackCircle"/>
              回顾
            </ion-button>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_choices_block" id="div_feeling_choices">
            <ChatMessage>{{ user_alias }}，{{ first_feeling_prompt }}</ChatMessage>
            <ChatMessage>我列出了一些你可能有的感受。<br/>请慢下来细细体会，逐一勾选。</ChatMessage>
            <ion-item v-for="(feeling, i) in feeling_choices" :key="i" lines="none" class="transparent checkItem">
              <ion-checkbox slot="start" v-model="checked_feelings[feeling]"></ion-checkbox>
              <ion-label>{{ feeling }}</ion-label>
            </ion-item>
            <ChatSubmit @click_submit="submit_feeling_choices()" class='endChoice'/>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_block" id="div_body">
            <ChatMessage>体会一下，情绪感受有没有引发你身体上不舒服的感觉？</ChatMessage>
            <div class='choiceGroup'>
              <div @click="click_body('yes')">
                <ChatChoice>对，有的</ChatChoice>
              </div>
              <div @click="click_body('no')">
                <ChatChoice>现在没有</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_detail_block" id="div_body_detail">
            <ChatMessage class='hidden'>你身体上有什么样的反应?</ChatMessage>
            <ChatMessage class='hidden'>比如：胸闷，喉咙卡，肩膀紧绷，头痛什么的...</ChatMessage>
            <div class='hidden'>
              <ChatInputArea v-model="body_detail" @click_submit="submit_body_detail()"></ChatInputArea>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_other_block" id="div_feeling_other">
            <ChatMessage class='hidden'>感谢你的敞开和信任。</ChatMessage>
            <div v-if="feeling_chosen">
              <ChatMessage class='hidden'>根据以上梳理出来的内容，我们一起来对你的感受做个总结吧。</ChatMessage>
              <ChatMessage class='hidden'>你感到：<b>{{ feeling_chosen }}</b></ChatMessage>
            </div>
            <ChatMessage class='hidden' v-if="has_body_sensation">你有<b>身体上的不舒服</b>
              <span v-if="body_detail">：<b>{{ body_detail }}</b></span></ChatMessage>
            <ChatMessage class='hidden'>
              <span v-if="g_feelings_yes.length">你还有其他感受吗？</span>
              <span v-else>你有其他感受吗？</span>
              比如：<i>{{ feeling_hint }}</i> ......
            </ChatMessage>
            <ChatMessage class='hidden'>你可以查看
              <router-link :to="{name:'Feeling'}">感受词汇表</router-link>
            </ChatMessage>
            <div class='choiceGroup hidden' id='feeling_other_choice'>
              <div @click="click_feeling_other('no')">
                <ChatChoice>没有了</ChatChoice>
              </div>
              <div @click="click_feeling_other('yes')">
                <ChatChoice>有，我还有其他感受</ChatChoice>
              </div>
            </div>
            <div v-if='show_feeling_other_input_block'>
              <ChatInputArea v-model="feeling_other" placeholder="请输入最主要的1-3个其他感受。你还感到："
                             @click_submit="submit_feeling_other()"></ChatInputArea>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feeling_order_block" id="div_feeling_order">
            <ChatMessage>{{ feeling_order_prompt }}</ChatMessage>
            <div class='choiceGroup'>
              <div v-for="(choice, i) in feeling_choices" :key="i" @click="click_feeling_order(i)">
                <ChatChoice>{{ choice.split('：')[0] }}</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_body_ex_block" id="div_body_ex">
            <ChatMessage class='hidden'>{{ user_alias }}，你身体上有一些不舒服的感觉。</ChatMessage>
            <ChatMessage class='hidden'>现在，我邀请你跟着我的伙伴玉鹿做一段身体感觉的冥想练习。</ChatMessage>
            <ChatMessage class='hidden'>等你准备好了，可以按“播放”，开始冥想。</ChatMessage>
            <div class='hidden'>
              <img id='body_ex_bg' src="/assets/icon/meditation.gif"/>
              <!-- <video id='body_ex_bg' src="/assets/icon/meditation.mp4" autoplay loop webkit-playsinline playsinline/> -->
              <div class='center'>
                <ion-button id="ex_audio_btn" shape='round' class='navBtn' @click="toggleAudio()">播放</ion-button>
              </div>
            </div>
            <div class="hidden bottom-input">
              <ion-button id="body_ex_next" fill="clear" class="ion-float-right"
                          @click="show_imagine">
                <ion-icon :icon="chevronForwardCircle"/>
                跳过
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_imagine_block" id='div_imagine'>
            <div id="imagine_guide">
              <ChatMessage class='hidden'>根据你的感受，我想邀请你再做一个觉察。</ChatMessage>
              <ChatMessage class='hidden'>现在请你轻轻地闭上眼睛，把注意力收回到你的内在。</ChatMessage>
              <ChatMessage class='hidden'>你会看到一个画面慢慢呈现出来。停留一会儿，然后用语言描述这个画面。</ChatMessage>
              <!-- <ChatMessage class='hidden'>比如一匹马艰难地拉着一辆沉重的大车</ChatMessage>
              <ChatMessage class='hidden'>又比如站在沙滩上望着一望无际的大海，不知该去向何方。</ChatMessage>
              <ChatMessage class='hidden'>{{ user_alias }}，请轻轻闭上眼睛，用两分钟时间想一下你的画面。</ChatMessage> -->
            </div>
            <div class='choiceGroup hidden' id='imagine_choice'>
              <div @click="click_imagine('yes')">
                <ChatChoice>有一个画面</ChatChoice>
              </div>
              <div @click="click_imagine('no')">
                <ChatChoice>暂时没有</ChatChoice>
              </div>
            </div>
            <div v-if="show_imagine_detail_block" id="div_imagine_detail">
              <ChatMessage>这个画面是什么样的呢？</ChatMessage>
              <ChatInputArea v-model="imagine_detail" @click_submit="submit_imagine_detail()">
              </ChatInputArea>
            </div>
            <div v-if="ask_new_feeling">
              <ChatMessage>{{ user_alias }}，当你描述这个画面的时候，</ChatMessage>
              <ChatMessage v-if="prime_emotions">除了前面提到的<b>{{ prime_emotions }}</b>...</ChatMessage>
              <ChatMessage>还会有一些新的情绪出来吗？
                <router-link :to="{name:'Feeling'}">感受词汇表</router-link>
              </ChatMessage>
              <div class='choiceGroup' id="feeling_new_choice">
                <div @click="click_feeling_new('yes')">
                  <ChatChoice>我有新的情绪</ChatChoice>
                </div>
                <div @click="click_feeling_new('no')">
                  <ChatChoice>没有</ChatChoice>
                </div>
              </div>
              <div v-if="show_new_feeling_block">
                <ChatInputArea v-model="feeling_new" @click_submit="submit_new_feeling()"
                               placeholder="请输入新的情绪"></ChatInputArea>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_needs_block" id='div_need'>
            <div v-if="need_detail_confirm" id="div_need_confirm">
              <ChatMessage>嗯，{{ need_repeat_start }}</ChatMessage>
              <ChatMessage>{{ need_repeat }}</ChatMessage>
              <div class='pause'></div>
            </div>
            <div id="div_need_prompt">
              <div v-if="first_need">
                <ChatMessage>{{ user_alias }}，你勇敢面对了自己的感受，这是自我了解的重要过程。</ChatMessage>
                <ChatMessage v-if="g_user_chat_history.feeling_new">
                  也谢谢你让我知道，你有了新的感受：{{ g_user_chat_history.feeling_new }}
                </ChatMessage>
                <ChatMessage>下面我们的话题要深入一点了。</ChatMessage>
                <ChatMessage v-if="prime_feeling">你感到{{ prime_feeling }}，有没有可能是因为：</ChatMessage>
              </div>
              <ChatMessage id="need_question"><span v-if="!first_need"></span>
                {{ do_you_need_question }}
              </ChatMessage>
              <div class='choiceGroup' id='need_choice'>
                <div @click="click_need('yes')">
                  <ChatChoice>是的</ChatChoice>
                </div>
                <div @click="click_need('no')">
                  <ChatChoice>不是/不太相关</ChatChoice>
                </div>
              </div>
            </div>
            <div v-if="show_need_detail_block" id="div_need_detail">
              <ChatMessage>描述一下，这对你意味着什么呢？<br/>（然后可以补充说说你有多么渴望它，渴望它多久了，等等...）</ChatMessage>
              <ChatInputArea v-model="need_detail" @click_submit="submit_need_detail()"></ChatInputArea>
              <ion-button fill="clear" class="ion-float-right endChoice"
                          @click="submit_need_detail()">
                <ion-icon :icon="chevronForwardCircle"/>
                暂时没有要说的了
              </ion-button>
              <ion-button fill="clear" class="ion-float-left endChoice" router-link="/review">
                <ion-icon :icon="chevronBackCircle"/>
                回顾
              </ion-button>
            </div>
            <!-- <div v-if="need_detail_confirm" id="div_need_confirm">
              <ChatMessage class='hidden'>嗯，{{user_alias}}，{{need_repeat_start}}</ChatMessage>
              <ChatMessage class='hidden'>{{need_repeat}}</ChatMessage>
              <div class="hidden">
                <ion-button fill="clear" class="ion-float-right" @click="show_next_conversation_of_need(false)">
                <ion-icon :icon="chevronForwardCircle" />继续 </ion-button>
              </div>
            </div> -->
          </div>
        </Transition>

        <Transition>
          <div v-show="show_recap_block" id="div_recap">
            <div v-for="(sentence, index) in recaps" :key="index" class='hidden' :id="`recap${index}`">
              <div v-if="sentence">
                <ChatMessage> {{ sentence }}</ChatMessage>
              </div>
              <div v-else class='pause'></div>
            </div>
            <div id='recap_choice' class='hidden'>
              <ChatMessage>
                <span v-if="g_user_chat_history.feelings_yes || g_user_chat_history.feeling_new">你愿意让我陪着你，静静地跟你的感受呆一会儿吗？</span>
                <span v-else> 你愿意让我陪着你，静静地呆一会儿吗？</span>
              </ChatMessage>
              <div class='choiceGroup'>
                <div @click="click_cooldown('Y')">
                  <ChatChoice>好的</ChatChoice>
                </div>
                <div @click="click_cooldown('N')">
                  <ChatChoice>不需要</ChatChoice>
                </div>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-show="show_cool_down_block" id='div_cooldown'>
            <div v-for="(sentence, index) in cool_downs" :key="index" class='hidden' :id="`cool_down${index}`">
              <div v-if="sentence">
                <ChatMessage> {{ sentence }}</ChatMessage>
              </div>
              <div v-else class='pause'></div>
            </div>
            <!-- <ion-icon color="primary" size="large" :icon="volumeMuteOutline"/> -->
            <div id="cool_down_nature" class="hidden">
              <ion-button fill="clear" class="ion-float-right"
                          @click="show_positive">
                <ion-icon :icon="chevronForwardCircle"/>
                下一步
              </ion-button>
              <ion-item lines="none" class="transparent">
                <ion-label>大自然背景音</ion-label>
                <ion-toggle slot="start" mode="ios" id="natureToggle"
                            @ionChange="toggleNatureAudio($event.target.checked)"
                            checked>
                </ion-toggle>
                <ion-select id="natureSelect" interface="popover" v-model="nature" mode="ios" text-center
                            @ionChange="playNatureSound()">
                  <ion-select-option value='雨声' text-center>雨声</ion-select-option>
                  <ion-select-option value='海浪' text-center>海浪</ion-select-option>
                  <ion-select-option value='瀑布' text-center>瀑布</ion-select-option>
                  <!-- <ion-select-option value='鸟鸣' text-center>鸟鸣</ion-select-option> -->
                </ion-select>
              </ion-item>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_positive_block" id='div_positive'>
            <div v-for="(sentence, index) in positives" :key="index" class='hidden'>
              <ChatMessage class="hidden"> {{ sentence }}</ChatMessage>
            </div>
            <div class="hidden">
              <ion-button fill="clear" class="ion-float-right" @click="show_otherside">
                <ion-icon :icon="chevronForwardCircle"/>
                下一步
              </ion-button>
              <ion-button fill="clear" class="ion-float-left" router-link="/review">
                <ion-icon :icon="chevronBackCircle"/>
                回顾
              </ion-button>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_otherside_block" id='div_otherside'>
            <div id='otherside_init'>
              <ChatMessage class="hidden">刚才的谈话中，我的重点是倾听、理解你的感受和需要。
              </ChatMessage>
              <ChatMessage class="hidden">
                现在，你愿不愿意尝试从{{ otherside }}的角度，看看{{ otherside }}可能有哪些感受和需要呢？
              </ChatMessage>
              <ChatMessage class="hidden">{{ otherside }}的感受和需要可能和你最初想的不太一样</ChatMessage>
              <ChatMessage class="hidden">如果你重视与{{ otherside }}的关系，这个练习可能会带来新的视角</ChatMessage>
              <ChatMessage class="hidden">或许你自己就找到了如何应对问题的方式了。</ChatMessage>
              <ChatMessage class="hidden">{{ user_alias }}, 你愿意尝试吗？</ChatMessage>
              <div class='choiceGroup hidden' id='otherside_choice'>
                <div @click="click_otherside('yes')">
                  <ChatChoice>我愿意</ChatChoice>
                </div>
                <div @click="click_otherside('no')">
                  <ChatChoice>暂时不想</ChatChoice>
                </div>
              </div>
            </div>
            <div v-if="show_otherside_input" id="div_otherside_input">
              <div id='otherside_start'>
                <ChatMessage class="hidden">你选择尝试从{{ otherside }}的角度看问题。
                </ChatMessage>
                <ChatMessage class="hidden">如果现在你尝试换位思考，{{ otherside }}可能的感受、需要、状态有哪些呢？
                </ChatMessage>
                <ChatMessage class="hidden">闭上眼睛，代入{{ otherside }}，慢慢体会，说说看。</ChatMessage>
                <ChatMessage class="hidden">你可以查看
                  <router-link :to="{name:'Feeling'}">感受词汇表</router-link>
                  和
                  <router-link :to="{name:'Need'}">需要词汇表</router-link>
                </ChatMessage>
              </div>

              <ChatMessage v-if="otherside_prompt">{{ otherside_prompt }}</ChatMessage>
              <div class="invisible" id="otherside_text">
                <ChatInputArea v-model="otherside_input" @click_submit="submit_otherside()"
                               placeholder="请说说你的思考和体会"></ChatInputArea>
              </div>
            </div>
            <div id="otherside_end">
              <ChatMessage class='hidden'>
                你愿意尝试从{{ otherside }}的角度去体会感受和需求，这是非常不容易的一步。
              </ChatMessage>
              <ChatMessage class='hidden'>这份努力，值得感谢、尊重和欣赏。</ChatMessage>
              <ChatMessage class='hidden'>这多么好啊！</ChatMessage>
              <div class='hidden'>
                <ion-button fill="clear" class="ion-float-right" @click="show_cbt">
                  <ion-icon :icon="chevronForwardCircle"/>
                  下一步
                </ion-button>
                <ion-button fill="clear" class="ion-float-left" router-link="/review">
                  <ion-icon :icon="chevronBackCircle"/>
                  回顾
                </ion-button>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_cbt_block" id="div_cbt">
            <div id="div_cbt_init">
              <div id='cbt_choice'>
                <!-- <ChatMessage class="hidden">每个人的感受和需要都是真实存在的。</ChatMessage>
                <ChatMessage class="hidden">我们不需要内疚自责。可以尝试看见它，允许它，接纳它，理解它·。</ChatMessage> -->
                <ChatMessage class="hidden">你有没有留意到，感受背后，往往有一些想法或信念。这些想法、信念虽然未必准确可靠，却在影响着一个人对事情的看法，解读，评价。 
                  <router-link :to="{name:'cbt'}"><b>请看一个例子</b></router-link>。
                </ChatMessage>
                <ChatMessage class="hidden">我想邀请你进一步探索自己的想法/信念，来解决自己的情绪困扰。
                </ChatMessage>
                <ChatMessage class="hidden">这对你可能是一种挑战，你要试一下吗？</ChatMessage>
                <!-- <ChatMessage class="hidden">下面的一些质疑观点的问话可能会引起你的不适。请记住，你是自由的，可以随时选择继续或退出。</ChatMessage>
                <ChatMessage class="hidden">你的感受是我最关心的，我在这里一如既往地陪伴你。这部分探索需时约5-15分钟。</ChatMessage> -->
                <div class='choiceGroup hidden'>
                  <div @click="click_cbt('yes')">
                    <ChatChoice>我要挑战</ChatChoice>
                  </div>
                  <div @click="click_cbt('no')">
                    <ChatChoice>我要跳过</ChatChoice>
                  </div>
                </div>
              </div>
              <div v-if="show_thoughts_input">
                <ChatMessage v-if="g_prime_feelings.length || g_user_chat_history.feeling_new">
                  （你的感受：<span class="feelingSpan" v-for="(feeling, i) in g_prime_feelings" :key="i">
                  {{ feeling.split('：')[0] }}</span>
                  {{ g_user_chat_history.feeling_new }}）
                </ChatMessage>
                <ChatMessage>请尝试写出你感受的背后，值得探索的1-3个想法/信念。</ChatMessage>
                <ChatMessage v-if="thought_examples.length">
                  例如：<span class="exampleSpan" v-for="(cbt_ex, i) in thought_examples" :key="i">
                <span v-if="thought_examples.length > 1">{{ i + 1 }}.</span> {{ cbt_ex }}</span>
                </ChatMessage>
                <ChatMessage>这些例子仅供参考。请用你自己的语言，写出你真正的想法：</ChatMessage>
                <ChatInput v-model="thought1">1</ChatInput>
                <ChatInput v-model="thought2">2</ChatInput>
                <ChatInput v-model="thought3">3</ChatInput>
                <ChatSubmit @click_submit="submit_thoughts()"/>
              </div>
            </div>
            <div v-if="show_thoughts_question">
              <ChatMessage>{{ thought_init }}</ChatMessage>
              <ChatMessage>想法 <b>{{ thought_curr }}</b></ChatMessage>
              <div v-if="check_thought">
                <ChatMessage>{{ cbt_question }}</ChatMessage>
                <div v-if="cbt_choice_index==0">
                  <ChatInputArea v-model="cbt_answer" @click_submit="submit_cbt_answer()"></ChatInputArea>
                </div>
                <!-- <div class='choiceGroup' v-if="cbt_choice_index==1">
                  <div @click="click_thought('yes')"><ChatChoice>可能有不准确或不全面</ChatChoice></div>
                  <div @click="click_thought('no')"><ChatChoice>没有，它很准确、到位</ChatChoice></div>
                </div> -->
                <div style='text-align:left;margin:1em' v-if="cbt_choice_index==2">
                  <div v-for="(choice, i) in cbt_err_choices" :key="i" @click="click_thought_err(i, true)">
                    <ChatChoiceLong>{{ choice }}</ChatChoiceLong>
                  </div>
                  <!-- <div @click="click_thought_err(-1, true)"> -->
                  <div @click="click_thought_err(-1, false)">  
                    <ChatChoiceLong>没有这几个问题。</ChatChoiceLong>
                  </div>
                </div>
                <div style='text-align:left;margin:1em' v-if="cbt_choice_index==3">
                  <div v-for="(choice, i) in cbt_err_choices2" :key="i" @click="click_thought_err(i, false)">
                    <ChatChoiceLong>{{ choice }}</ChatChoiceLong>
                  </div>
                  <div @click="click_thought_err(-1, false)">
                    <ChatChoiceLong>没有这几个问题。</ChatChoiceLong>
                  </div>
                  <div @click="click_thought_err(-2, true)"><ChatChoiceLong>回到上一页选项。</ChatChoiceLong></div>    

                  <!-- <ion-button fill="clear" class="ion-float-left" @click="click_thought_err(-2, true)">
                    <ion-icon :icon="chevronBackCircle"/>
                    回到上一页选项
                  </ion-button> -->
                </div>
                <div id="thought_detail" v-if="show_thought_detail">
                  <!-- <ChatMessage>能不能展开说说：你认为这个想法存在上述问题的原因是？</ChatMessage>
                  <ChatInputArea v-model="thought_err_detail">请输入</ChatInputArea> -->
                  <ChatMessage>如果把这个想法改得比较客观全面，可能改成什么样子？</ChatMessage>
                  <ChatInputArea v-model="thought_correction"
                                 @click_submit="submit_thought_correction()"></ChatInputArea>
                </div>
              </div>
              <!-- <ion-button fill="clear" class="ion-float-right hidden" style='margin-top: 2em' id="skip_cbt"
                @click="show_advice">
                <ion-icon :icon="chevronForwardCircle" />跳过这个环节
              </ion-button>  -->
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_cbt_general" id="div_cbt_general">
            <div v-if="g_user_chat_history.thoughts">
              <ChatMessage>总结一下你的想法。</ChatMessage>
              <div v-for="(thought, i) in g_user_chat_history.thoughts" :key="i">
                <ChatMessage>想法 {{ i + 1 }}：{{ thought.thought }}
                  <div v-if="thought.correction">你改成了：{{ thought.correction }}</div>
                </ChatMessage>
              </div>
            </div>
            <ChatMessage>{{ cbt_general_question }}</ChatMessage>
            <ChatInputArea v-model="cbt_general_answer" @click_submit="submit_cbt_general()"
                           placehodler='请输入你的想法'></ChatInputArea>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_goal_block" id="div_goal"> 
            <div id="div_goal1">
              <ChatMessage>如果你的问题解决了，你达到了一个理想的状态，那会是什么样的状态？你会怎么做呢？
              </ChatMessage>
              <ChatInputArea v-model="goal_input" @click_submit="submit_goal()"
                             placeholder="请描述你问题解决以后的样子"></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_goal()">
                <ion-icon :icon="chevronForwardCircle"/>
                没想出来/跳过这页
              </ion-button>
            </div> 
          </div>
        </Transition>    

        <Transition>
          <div v-if="show_exception_block" id="div_exception"> 
            <div id="div_exception1">
              <ChatMessage>你以往的经历里，有过接近理想状态的时候吗？或者问题稍微轻一点的时候，哪怕只是一点点？当时你做了什么？
              </ChatMessage>
              <ChatInputArea v-model="exception_input" @click_submit="submit_exception()"
                             placeholder="请描述比较好，或者问题比较轻时的场景"></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_exception()">
                <ion-icon :icon="chevronForwardCircle"/>
                没想出来/跳过这页
              </ion-button>
            </div> 
          </div>
        </Transition>  

        <Transition>
          <div v-if="show_behavior_block" id="div_behavior"> 
            <div id="div_behavior1">
              <ChatMessage>认真想想，今后几天里，你可以做些什么，让情况变得好一点？具体计划一下，行动的时间地点，怎么做：
              </ChatMessage>
              <ChatInputArea v-model="behavior_input" @click_submit="submit_behavior()"
                             placeholder="请描述你行动改变的计划"></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_behavior()">
                <ion-icon :icon="chevronForwardCircle"/>
                没想出来/跳过这页
              </ion-button>
            </div> 
          </div>
        </Transition>  

        <Transition>
          <div v-if="show_advice_block" id="div_advice">       
            <div id="div_advice_init">
              <ChatMessage>谈话进行到这里，你能想到什么感触和收获吗？有没有产生新的观点，或者看到不同的角度？
              </ChatMessage>
              <ChatInputArea v-model="advice_input" @click_submit="submit_advice()"
                             placeholder="请输入你的新观点、新角度"></ChatInputArea>
              <ion-button fill="clear" class="center endChoice"
                          @click="submit_advice()">
                <ion-icon :icon="chevronForwardCircle"/>
                暂时没有
              </ion-button>
            </div>
            <div v-if="advice_response" id="div_advice_response">
              <ChatMessage class="hidden">{{ advice_input_feedback }}</ChatMessage>
              <!-- <ChatMessage class="hidden">你的这份认真和勇气，是这么值得欣赏。</ChatMessage>
              <ChatMessage class="hidden">请再给自己一个温暖的拥抱吧！</ChatMessage> -->
              <div v-if="advice">
                <ChatMessage class="hidden">下面是我的一点建议，不妨听听看：</ChatMessage>
                <ChatMessage class="hidden">{{ advice }}</ChatMessage>
              </div>
              <div class="hidden">
                <ion-button fill="clear" class="ion-float-right" @click="show_end_score()">
                  <ion-icon :icon="chevronForwardCircle"/>
                  下一步
                </ion-button>
                <ion-button fill="clear" class="ion-float-left" router-link="/review">
                  <ion-icon :icon="chevronBackCircle"/>
                  回顾
                </ion-button>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_end_score_block" id="div_end_score">
            <!-- <ChatMessage>{{ user_alias }}，聊到这里，我很关注你此刻的情绪</ChatMessage>
            <ChatMessage>如果给你的烦恼再打一次分</ChatMessage>
            <ChatMessage>零分最平静，十分最强烈，现在你会打几分？</ChatMessage> -->
            <ChatMessage>这次谈话，对你有多少帮助？</ChatMessage>
            <div class='choiceGroup'>
              <!-- <div v-for="i in 11" :key="i" @click="click_score(i, 1)">
                <ChatChoice>{{ i-1 }} </ChatChoice>
              </div> -->
              <div v-for="(choice, i) in effect_choices" :key="i" @click="click_effect_choice(choice)">
                <ChatChoice>{{ choice }}</ChatChoice>
              </div>
            </div>
          </div>
        </Transition>

        <Transition>
          <div v-if="show_feedback_block">
            <div id="div_feedback">
              <div id='feedback_start'>
                <div v-if="score_improved">
                  <ChatMessage class="hidden">看起来你的情绪有所缓解。</ChatMessage>
                  <ChatMessage class="hidden">我很好奇，这个积极的变化是怎么发生的呢？
                    <router-link :to="{name:'Review'}">谈话要点</router-link>
                  </ChatMessage>
                  <ChatMessage class="hidden">想想让你印象最深刻的是什么呢？：</ChatMessage>
                  <ChatMessage class="hidden">是谈感受的部分，谈需要的部分，还是其他呢？
                    （比如，看到自己的积极努力？
                    <span v-if="has_body_sensation">身体的冥想练习？</span>
                    <span v-if="g_user_chat_history.thoughts || g_user_chat_history.cbt_general">推敲想法/信念？</span>
                    <span v-if="g_user_chat_history.otherside && Object.keys(g_user_chat_history.otherside).length">换位思考？</span>
                    <span v-if="g_user_chat_history.goal">清楚了想达到的目标？</span>
                    <span v-if="g_user_chat_history.exception">看见了做得比较好的时候？</span>
                    <span v-if="g_user_chat_history.behavior">有了行动计划？</span>
                    ... ）
                  </ChatMessage>
                </div>
                <div v-else>
                  <ChatMessage class="hidden">看来烦恼有点重。</ChatMessage>
                  <ChatMessage class="hidden">我会继续想办法，看看怎么更好地帮到你。</ChatMessage>
                  <ChatMessage class="hidden">你希望我在哪方面改进呢？</ChatMessage>
                </div>
              </div>
              <div id="feedback_input">
                <ChatMessage v-if="feedback_prompt">{{ feedback_prompt }}</ChatMessage>
                <div class="hidden">
                  <ChatInputArea v-model="user_feedback" @click_submit="submit_feedback()"></ChatInputArea>
                </div>
                <ChatMessage v-if="g_user_chat_history.feedback && g_user_chat_history.feedback.length==2">无论答案是有是无，请记住:<br/>我一直在这里。
                </ChatMessage>
              </div>
            </div>
            <div id='feedback_end'>
              <ChatMessage class="hidden">{{ user_alias }}， 谢谢你今天的来访。</ChatMessage>
              <div v-if="score_improved">
                <ChatMessage class="hidden">你的积极思考和改变，让我也受到了鼓舞。</ChatMessage>
                <ChatMessage class="hidden"><b>进长颈鹿粉丝团，请加微信号 NVCsimple</b></ChatMessage>
                <ChatMessage class="hidden"><b>把长颈鹿耳朵分享给亲友，帮助他们减少情绪内耗，让世界变美好一点，也让亲友和自己获赠鹿角：</b></ChatMessage>
                <ChatMessage class="hidden"><b>1. 点击右上角的三个点，转发小程序给亲友或微信群</b></ChatMessage>
                <ChatMessage class='hidden'><b>2. 帮助亲友更好地使用，转发给他们
                <a href="https://mp.weixin.qq.com/s/Zdp3W6LDMXEptCtq-mdpAQ " target="_blank">这篇文章</a></b>              
            </ChatMessage> 
              </div>
              <div v-else>
                <ChatMessage class="hidden">我会把你的反馈或意见告诉我那些很想要助人的设计者们，以提高能力，更好地帮助到人。</ChatMessage>
                <ChatMessage class="hidden">下回再聊，咱们一起进步。</ChatMessage>
              </div>
              <div class='choiceGroup hidden endChoice'>
                <div>
                  <ChatChoice router-link="/review">回顾谈话要点</ChatChoice>
                </div>
                <div @click="newChat">
                  <ChatChoice>开始新的谈话</ChatChoice>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <div class="bottom-input">
      <ion-button fill="clear" class="ion-float-right hidden" id="skip_cbt"
                  @click="show_goal">
        <ion-icon :icon="chevronForwardCircle"/>
        跳过探索“想法”的板块
      </ion-button>
    </div>
    <!-- <template v-slot:footer>
      <ion-footer class="ion-no-border">
        <ion-button fill="clear" class="ion-float-right hidden"  id="skip_cbt"
              @click="show_advice"><ion-icon :icon="chevronForwardCircle" />跳过此板块
        </ion-button> 
      </ion-footer>
    </template> -->
  </base-layout>
</template>

 <!-- Define multiple choice format for branch choice and details-->
<style scoped>  
.chat-choice {
  width: 70%;
  height: 300%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-sizing: border-box;
}
.content {
  width: 80%;
}
.choice {
  width: 100%;
  text-align: center;
  margin-bottom: 8px;
  font-size: 1.2em;
  font-weight: bold;
}
.detail {
  width: 100%;
  text-align: center;
  white-space: normal; /* Allows the text to wrap */
  word-wrap: break-word; /* Ensures long words break correctly */
  line-height: 1.4em; /* Adjusts the spacing between lines */
}
</style>

<script setup>
//import $ from "jquery";
import {
  //useIonRouter,
  toastController,
  loadingController,
  IonItem,
  IonLabel,
  IonToggle,
  //IonTextarea,
  IonIcon,
  IonButton,
  //IonText,
  IonFooter,
  IonSelect, IonSelectOption,
  IonCheckbox
} from '@ionic/vue';
import {chevronBackCircle, chevronForwardCircle,} from 'ionicons/icons';
import ChatMessage from '@/components/ChatMessage.vue';
import ChatChoice from '@/components/ChatChoice.vue';
import ChatChoiceLong from '@/components/ChatChoiceLong.vue';
import ChatSubmit from '@/components/ChatSubmit.vue';
import ChatInput from '@/components/ChatInput.vue';
import ChatInputArea from '@/components/ChatInputArea.vue';
import LoadingScreen from '@/components/LoadingScreen.vue';
import {store} from '@/store';
import {api} from '@/api';
import {supabase} from '@/supabase';
import {ref, onMounted, nextTick} from 'vue';
import {
  show_element_by_id, hide_element_by_id, delay, show_element_animated, scroll_to_bottom,
  loadAudio, toggleAudio, stopAudio, user_alias, gender, age, profession, nature, session, updateProfile,
  feeling_list, g_user_chat_history, use_supabase, blink_element, unblink_element, web_url, is_weixin,
  saveUserChatLocal, getUserChatLocal, g_ai_info, saveInfoLocal, getInfoLocal, deleteChatLocal, amount
} from '@/utils';

import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const chatpage_title = t('chatpage_title')

const debug = false;
const amount_per_chat = 99;
//const router = useIonRouter();
const user = store.user;
const isLoading = ref(true);
const new_user = ref(true);

const leaf_choices = ref([]);
const branch_choices = ref(['自我状态','婚姻 恋爱 育儿 家庭','学业 工作','性','其他']);
const branch_detail_choices = ref(['抑郁 焦虑 \n 压力 社交困难','',' ','性的烦恼 性骚扰 性取向','不属于以上话题']);
const effect_choices = ref(['很有帮助','有帮助','没帮助']);
const show_ai_block = ref(false);
const show_ai_result = ref(false);
// if ai_weak, default examples will be used; otherwise will be replaced by specific leaf info
const thought_examples = ref(['我预期会有不好的结果','我是这样解读的...','这样很糟糕','我应该/他应该/我必须...','我不够好']);

const ROOT_ID = 0; // the top parent_id, currently 0, which may change in the future
const parent_id = ref(ROOT_ID - 1); // will be updated when user clicks a node or choice
const choices = ref([]); //{"id":0, "parent_id":0, "choice":'', "feelings":'', "needs":''}]);
const feeling_choices = ref([]); // ask user to order feelings from these choices
const recaps = ref([]); // feeling & needs recap sentences before cool_down_block
const cool_downs = ref([]); // cool down sentences in cool_down_block
const positives = ref([]); // positive sentences in positive_block

const show_greeting = ref(true);
const show_chat_start = ref(false);
const show_charge_info = ref(false);
const show_tree_node_block = ref(false);
const show_start_score_block = ref(false);
const show_end_score_block = ref(false);
const show_details_input_block = ref(false);
const show_feelings_block = ref(false);
const show_body_block = ref(false);
const show_needs_block = ref(false);
const show_recap_block = ref(false);
const show_cool_down_block = ref(false);
const show_feeling_detail_block = ref(false);
const show_feeling_choices_block = ref(false);
const show_feeling_other_block = ref(false);
const show_need_detail_block = ref(false);
const show_body_detail_block = ref(false);
const show_feeling_other_input_block = ref(false);
const show_feeling_order_block = ref(false);
const show_body_ex_block = ref(false);
const show_imagine_block = ref(false);
const show_imagine_detail_block = ref(false);
const show_new_feeling_block = ref(false);
const show_positive_block = ref(false);
const show_otherside_block = ref(false);
const show_otherside_input = ref(false);
const show_cbt_block = ref(false);
const show_thoughts_input = ref(false);
const show_thoughts_question = ref(false);
const show_thought_detail = ref(false);
const show_cbt_general = ref(false);
const show_feedback_block = ref(false);
const show_goal_block = ref(false);
const show_exception_block = ref(false);
const show_behavior_block = ref(false);
const show_advice_block = ref(false);
const advice_response = ref(false);
const ai_more_input = ref(false);
const ai_weak = ref(false);
const ai_weak_topic = ref('');
const ai_weak_placeholder = ref('请输入你想讨论的话题...')
//const ai_detail_no = ref('跳过，不聊具体情况');
const ai_detail_no = ref('');

const feeling_curr = ref('');
const feeling_detail_question = ref('');
const first_feeling = ref(true);
const first_feeling_prompt = ref('谢谢你的信任和敞开。\n清楚地意识到内心真实的感受，对情绪疏导是很重要的。\n');
const checked_feelings = ref([]);
const first_need = ref('');
const feeling_detail = ref('');
const has_body_sensation = ref(false);
const body_detail = ref('');
const need_detail = ref('');
const need_detail_confirm = ref('');
const feeling_chosen = ref('');
const feeling_other = ref('');
const feeling_hint = ref('');
const prime_feeling = ref('');
const prime_emotions = ref(''); // g_prime_feelings excluding body sensation
const imagine_detail = ref('');
const ask_new_feeling = ref(false);
const feeling_new = ref('');
const otherside_prompt = ref('');
const otherside_input = ref('');
const thought1 = ref(''), thought2 = ref(''), thought3 = ref('');
const thought_curr = ref(''), cbt_question = ref('');
const check_thought = ref(false);
const cbt_choice_index = ref(2);
const cbt_answer = ref('');
const cbt_general_question = ref('');
const cbt_general_answer = ref('');
const goal_input = ref('');
const exception_input = ref('');
const behavior_input = ref('');
const advice_input = ref('');
const advice_input_feedback = ref('');
const score_improved = ref(false);
const user_feedback = ref('');

const node_selection_question = ref('');
const do_you_need_question = ref('');
const feeling_order_prompt = ref('');
const advice = ref('');

const user_input_details = ref('');
//const user_input_question = ref('说说具体情况？发生了什么？'); // non-ai version
//const user_input_question = ref('你还好吗？有什么心事？请先概括地说说。');
const user_input_question = ref('今天想跟我聊点什么呢？用一两句话描述。');
const ai_require_input = ref(false); // check if user input length meets min ai requirement
const input_prompt = ref('使用语音输入法更方便哦');
const thought_init = ref("");
const feedback_prompt = ref('');
const otherside = ref("");
const need_repeat_start = ref('');
const passive_response = ref('');
const need_repeat = ref('');
const thought_err_detail = ref('');
const thought_correction = ref('');
const AI_INPUT_MIN = 5;
const AI_INPUT_MAX = 250;

//const review_question = "以下是你的描述。请看看是否还需要修改、补充。";
const CUTOFF_OF_USER_INPUT_THAT_NEEDS_DETAILS = 20;
var review_question = "这是你刚刚的描述\n需要修改或补充吗？"; // will be replaced by the following string if user input is too short
const review_question_that_needs_details = "可以补充得更具体一点吗？";
const review_question_for_trouble = "讨论你真正的烦恼，具体一点，我们的谈话才比较有效果。\n要不要修改补充一下？";
 
const use_weak_feelings_path = 1; // both ai_weak and ai_leaf use original ai_weak feeling_choices route

let g_ai = true;
let g_nature_audio;
let g_feelings, g_needs, g_feeling_need_recap, g_otherside;
let g_feelings_yes = [], g_needs_yes = [], g_prime_feelings = [];
//let g_need_prompt;
let g_next_feeling_index = -1, g_next_need_index = -1;
let g_thought_index = -1, g_cbt_question_index = -1, g_otherside_index = -1;
let g_feeling_curr, g_need_curr;
let g_nodeId_node_map = {};
let g_ai_leaf = false;
let g_chat_id;
let g_saved_nature;
//let g_ai_summary = 0;

const feeling_vocab = ['悲痛', '惆怅', '苦恼', '不幸福', '恐怖', '无奈', '忧虑', '惊恐', '愤怒', '羞愧', '担忧',
  '憋闷', '疲劳不堪', '心神不宁', '窘迫', '受挫', '懊恼', '为难', '脆弱', '虚弱', '难为情', '麻木', '寂寞', '战战兢兢',
  '失望', '伤心', '郁闷', '恼怒', '气恼', '犹豫', '不舒服', '气愤', '厌恶', '震惊', '茫然', '没精打采', '恐惧', '胆怯',
  '烦', '心烦意乱', '羡慕', '疏远', '孤单', '生气', '警惕', '绝望', '委靡不振', '孤独', '崩溃', '困乏', '抑郁', '心急如焚',
  '困惑', '担心', '嫉妒', '难过', '吃惊', '怨恨', '不自然', '无助', '伤感', '忧愁', '不高兴', '遗憾', '惊讶', '丧气',
  '气馁', '不安', '精疲力竭', '害怕', '不喜欢', '无望', '失魂落魄', '悔恨', '怀疑', '凄凉', '疲乏', '无力', '着急', '泄气',
  '烦恼', '慌乱', '疲倦', '心疼', '沮丧', '疑惑', '惊呆', '心碎', '精疲力尽', '厌烦', '透支', '不耐烦', '恐慌', '迷茫',
  '轻蔑', '后悔', '隔阂', '烦躁', '自责', '惭愧', '不知所措', '无精打采', '忧伤', '悲观', '软弱', '紧张', '挫败', '灰心',
  '晕头转向', '疲劳', '恼火', '疲惫', '悲伤', '内疚', '痛恨', '失意', '尴尬', '悲哀', '昏昏欲睡', '失落', '冷漠',
  '透不过气', '不满', '思念', '冷淡', '疲惫不堪', '挫折', '焦虑', '不快', '慌张', '沉重', '妒忌', '忐忑', '惊吓', '纠结',
  '愧疚', '害羞', '憋屈', '迷惑', '压抑', '自我怀疑', '不确定', '期盼', '平淡', '心酸', '悲愤', '走投无路', '心灰意冷',
  '不舍', '疑虑', '焦急', '痛苦', '挣扎', '困惑不解', '烦闷', '羞辱', '迟疑', '羞耻', '不开心', '自卑', '心神不定', '牵挂',
  '无所适从', '伤心欲绝', '屈辱', '左右为难', '累', '迷惘', '卑微', '心慌', '孤立无援', '混乱', '痛苦', '迷芒', '畏惧',
  '无聊', '疏离', '憎恨', '心乱如麻', '焦躁', '着迷', '耻辱', '哀伤', '排斥', '低迷', '懊悔', '心痛', '苦闷', '受伤',
  '急切', '低落', '颓废', '烦心', '委屈', '惋惜'];
let g_feelings_from_input = new Set(); // feeling vocab from user input, no dups

let feeling_example = feeling_list; // give user hint when asking for other feelings

const cbt_questions = ['这个想法，是否可能有以下的"过分解读"：\n（选择最符合的一个）',
  '这个想法，是否可能有以下的"负面思维"：\n（选择最符合的一个）',
  '有哪些证据支持这个想法？', '有证据反对这个想法吗？',];

const cbt_err_choices = ['以偏概全：根据个别或片面的细节下结论，没有全面地看问题。',
  '夸大或缩小了某一特定事件，人物，或因素的影响力。',
  '“非黑即白，两级分化”的倾向，用全有或全无，全对或全错的方式看待问题，把生活看成非黑即白的单色世界，没有中间色彩。',
  '主观诠释，没有充足和客观的证据，仅凭自己的主观感受就下结论。',
  '感情用事，根据自己的主观感受得出结论。','忽视了自己的优点和长处，或者没看到事情好的一面。',
  '过度强调了事件的负面影响或严重性，不由自主往坏处想。',
  '是在给自己或他人贴负面标签。',
  '有“自我归咎”倾向，就是说，虽然这件事情与自己无关，却以为是针对自己，或由自己造成。',
  '用“应该”“必须”的思维看问题，没有用弹性、开阔、丰富的视角。'];
const cbt_err_choices2 = ['忽视了自己的优点和长处，或者没看到事情好的一面。',
  '过度强调了事件的负面影响或严重性，不由自主往坏处想。',
  '是在给自己或他人贴负面标签。',
  '有“自我归咎”倾向，就是说，虽然这件事情与自己无关，却以为是针对自己，或由自己造成。',
  '用“应该”“必须”的思维看问题，没有用弹性、开阔、丰富的视角。'];
const cbt_general_questions = ['想一想，目前状况可能导致的最坏情况是什么？', '如果最坏情况发生了，你会如何面对？',
  '最好的可能性是什么？', '实际情况最可能会是什么样？', '如果这件事发生在你的朋友身上，你会怎么看待？你会对你的朋友说些什么？']
let cbt_general_index = -1;

async function getProfile() {
  console.log('getProfile', use_supabase, window._wcprms);
  // reset
  user_alias.value = '';
  gender.value = '';
  age.value = '';
  profession.value = '';
  nature.value = '';

  const loader = await loadingController.create({});
  const toast = await toastController.create({duration: 5000});
  await loader.present();
  try {
    //console.log('get profile user', user);
    if (use_supabase) {
      if (!user.id) return; // shouldn't happen, but just in case

      var {data, error, status} = await supabase
          .from('profiles')
          .select('user_alias, gender, age, profession, nature')
          .eq('id', user.id)
          .single();
      if (error && status != 406) throw error;
    } else {
      data = await api.userInfo();
// console.debug("get userInfo:", JSON.stringify(data,null,2) );

      //never login
      if (!data) {
        if (window._wcprms?.code) {
          //wait signIn result
          const res = await api.signInWithCode(window._wcprms.code, window._wcprms.recm);
          console.log('signin with code res', res);
          if (!res) {
            // 失败
            getProfile();
            return
          }
          if (res && res.code !== 0) {
            // 失败
            getProfile();
            return
          }
          delete window._wcprms;
          data = api.user();
        }
        //else web login
      } else {
        session.value = api.session();
        store.user = data;
        // console.log('DBG> session.user = ', JSON.stringify(session.user,null,2) );
//        session.user=data;
      }
    }
    if (data) {
      // console.log('getProfile', data);
      amount.value = use_supabase ? amount_per_chat : data.horns_green + data.horns_red;
      // console.log('horn amount', amount.value);
      if (data.gender) gender.value = data.gender;
      if (data.age) age.value = data.age;
      if (data.profession) profession.value = data.profession;
      nature.value = data.nature || '雨声';
      g_saved_nature = nature.value;
      if (data.user_alias) {
        user_alias.value = data.user_alias;
        new_user.value = false;
        resumeChat();
        //hide_element_by_id('div_greeting')
        //show_positive();
        //show_cbt();
        //show_the_cool_down_part();
        // score_improved.value = true;
        // show_feedback_block.value = true;
        // show_element_sequenced('div_feedback');
      }
    } else if (use_supabase) amount.value = amount_per_chat;
  } catch (error) {
    console.log('profile error')
    toast.message = error.message;
    await toast.present();
  } finally {
    console.log('user alias', user_alias.value);
    isLoading.value = false;
    await loader.dismiss();
  }
}

// function start_chat(){
//   g_ai = false;
//   user_input_question.value = '说说具体情况？发生了什么？';
//   hide_element_by_id('div_greeting');
//   g_user_chat_history.node_path = [];
//   click_tree_node(ROOT_ID);
//   show_element_animated(show_tree_node_block);
// }

function start_chat_ai() {
  hide_element_by_id('div_greeting');
  show_element_animated(show_details_input_block);
}

function process_alias() {
  let input = user_alias.value;
  input = input.replace(/(.+)吧$/, '$1');
  input = input.replace(/.+我/, '');
  input = input.replace(/我?.*[叫是]/, '');
  console.log('processed alias', input)
  if (input.length) user_alias.value = input;
}

async function submit_my_username() {
  console.log('submit_my_username',user_alias.value, user.id);
  process_alias(); // in case user input is sth like 我叫xx, 我的昵称是xx, 叫我xx吧

  updateProfile({user_alias: user_alias.value});
  await delay(1);

console.log('submit_my_username 22', JSON.stringify(api.user(), null,2) );
  hide_element_by_id('div_ask_alias');
  show_charge_info.value = true;
  show_element_sequenced('div_charge_info');
}

function chat_greeting() {
  hide_element_by_id('div_first_time');
  console.log('check horn', amount.value)
  if (amount.value >= amount_per_chat) { // enough amount
    hide_element_by_id('div_charge_info');
    show_chat_start.value = true;
  } else { // not enough, show charge info
    show_charge_info.value = true;
  }
  show_element_sequenced('div_greeting');
}

async function post_request(options = {}) {
  if (!use_supabase) return api.ai_req(g_chat_id, options);

  //let server_url = 'https://api.langev.com/changjinglu/';
  let server_url = 'http://localhost:9111';
  if (options && !options.uid) {
    console.log('post', user)
    options.uid = user.id; // '00d9ac26-7700-4510-b7d9-20e53c872b66'; //debug
  }

  const response = await fetch(server_url, {
    method: 'POST',
    headers: {'Content-Type': 'application/json'},
    body: JSON.stringify(options),
  });
  let error = null;
  if (!response.ok) {
    const message = `An error has occured: ${response.status}`;
    error = new Error(message);
  }
  const data = await response.json();
  return {data, error};
}

async function getDecisionTreeChildrenNodes(node_id) {
  const loader = await loadingController.create({});
  const toast = await toastController.create({duration: 5000});
  await loader.present();

  try {
    // let { data, error, status } = await supabase
    //   .from('decision_tree')
    //   .select(`*`)
    //   .eq('parent_id', node_id)
    //   .order('id')
    // if (error && status !== 406) throw error;

    let options = {uid: user.id, task: 'query_decision_tree', 'parent_id': node_id};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    if (error) throw error;
    if (!data) throw new Error('no data available');
    process_retrieved_tree_nodes(data, node_id);
  } catch (error) {
    toast.message = error.message;
    await toast.present();
  } finally {
    await loader.dismiss();
  }
}

async function click_tree_node(node_id) {
  reset_choices();
  g_user_chat_history.node_path.push(node_id);
  console.log(g_user_chat_history)

  let question = node_id == ROOT_ID ? "请问你有什么方面的烦恼呢?" : "问题再细化点？";
  node_selection_question.value = question;

  let leaf_node_reached = node_id in g_nodeId_node_map && g_nodeId_node_map[node_id]['feelings']
  if (!leaf_node_reached) {
    getDecisionTreeChildrenNodes(node_id);
    show_element_animated(show_tree_node_block);
  } else {
    let data_not_ready = g_nodeId_node_map[node_id]['feelings'] == 'TODO'
        || g_nodeId_node_map[node_id]['feelings'].startsWith('见下一个')
    if (data_not_ready) {
      let msg = 'Sorry, 内容还没有准备好'
      const toast = await toastController.create({duration: 5000});
      toast.message = msg;
      await toast.present();
    } else {
      console.log('leaf node:', g_nodeId_node_map[node_id])
      hide_element_by_id('div_tree');
      show_element_animated(show_start_score_block);

      get_leaf_info(g_nodeId_node_map[node_id]);

    }
  }
}

function get_leaf_info(leaf_node_selected) {
  g_feelings = leaf_node_selected['feelings'].split(/\s+/)||[]; // guard against multiple spaces
  g_needs = leaf_node_selected['needs'].split('\n');
  if (leaf_node_selected['CBT_examples']) {
    thought_examples.value = leaf_node_selected['CBT_examples'].split('\n');
  }

  // g_need_prompt = g_needs[0];
  // g_needs = g_needs.slice(1);
  g_otherside = leaf_node_selected['fn_of_the_other'].split('\n');
  if (g_otherside.length && g_otherside[0].length >= 2) {
    //g_user_chat_history.otherside = {};
    otherside.value = g_otherside[0].slice(0, 2);
  }
  advice.value = leaf_node_selected['advice_comment'];
  console.log('otherside', g_otherside)

  // save leaf info in case it needs to be recovered
  g_ai_info.feelings = g_feelings;
  g_ai_info.needs = g_needs;
  g_ai_info.advice_comment = advice.value;
  if (leaf_node_selected['CBT_examples']) g_ai_info.CBT_examples = thought_examples.value;
  if (otherside.value) g_ai_info.fn_of_the_other = g_otherside;
  saveInfoLocal();
}

function process_retrieved_tree_nodes(data, node_id) {
  choices.value = data;
  for (let node of data) {
    g_nodeId_node_map[node.id] = node
  }
  console.log('process retrieved tree node for :', node_id)
  if (node_id in g_nodeId_node_map) {
    parent_id.value = g_nodeId_node_map[node_id].parent_id;
  } else {
    parent_id.value = -1; // make sure "返回" won't show up at root
    //console.log('- no update on parent_id')
  }
  console.log('parent_id', parent_id.value)
}

function show_more_details() {   // 显示树洞环节
  if (g_ai) {
    //show_next_conversation_of_feeling();
    user_input_question.value = first_message;
    user_input_details.value = '';
    ai_more_input.value = true;
  }
  show_element_animated(show_details_input_block);
}

function click_score(score, stage) {
  if (!g_user_chat_history.scores) g_user_chat_history.scores = [];
  g_user_chat_history.scores[stage] = score;
  console.log('scores:', g_user_chat_history.scores)
  hide_element_by_id('div_start_score');
  if (stage == 0) {
    show_more_details();
  } else {
    g_user_chat_history.next = 'K';
    show_feedback();
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save score on server
}

function show_feedback() {
  hide_element_by_id('div_end_score');
  //score_improved.value = g_user_chat_history.scores[1] < g_user_chat_history.scores[0];
  score_improved.value = (['很有帮助', '有帮助'].includes(g_user_chat_history.effect))
  g_user_chat_history.feedback = [];
  show_feedback_block.value = true;
  show_element_sequenced('div_feedback');
}

function submit_feedback() {
  let end = !score_improved.value;
  let input = user_feedback.value.trim();
  // record feedback whether empty or not, so we know which question it corresponds to
  g_user_chat_history.feedback.push(input);
  saveUserChatLocal();
  hide_element_by_id('feedback_start');
  if (!end) {
    user_feedback.value = ''; //reset
    let feedback_num = g_user_chat_history.feedback.length;
    if (feedback_num == 1) {
      feedback_prompt.value = '这次访谈，有没有什么收获，可以帮助你应对以后类似的困惑？';
    } else if (feedback_num == 2) {
      feedback_prompt.value = `当我们陷入“情绪旋涡”时，容易忽略掉一些资源。\n`;
      feedback_prompt.value += `比如，也许你的身边有人可以倾听陪伴你，给你支持和帮助。\n`;
      feedback_prompt.value += `想想看，你身边有这样的人吗？会是谁呢？`;
    } else end = true;
  }
  if (end) {
    g_user_chat_history.next = 'C'; // complete
    deleteChatLocal();
    console.log('history ', g_user_chat_history);
    saveUserChatHistory(false, true); // save feedback, set ended to true
    console.log('feedback ', g_user_chat_history.feedback)
    hide_element_by_id('feedback_input');
    show_element_sequenced('feedback_end');
  }
}

//const first_message = `我想充分了解你的困扰，好好倾听你。\n可以多说一点吗？比如具体的情况、细节？`;
const first_message = `具体说说你的烦恼？`;
const chat_messages = ref([first_message]);
const disableInput = ref(false);

async function getLastChatElement() {
  await nextTick(); // wait for DOM to update
  const messages = document.querySelectorAll('.chat-message');
  return messages[messages.length - 1];
}

// new version: record each chat on screen and auto scroll
//const SUMMARY_INDICES = [1,2,3,4,5,7,9,11,13,15,17,20,23,26,30,34,38,42,47,52,58,64,80,90,100];
let g_summary_index = 0;

//let explained = false; // provide explanation if certain char (e.g 你) appears first time; end this block if already explained
async function ai_more_details(end = 0) {
  let last_message = chat_messages.value.slice(-1);
  const coping = "嗯，你是怎么反应的呢？";
  const coping_no = '我并没有做什么';
  const no_more_detail = '情况说完了，进入下一环节';
  //const explanation = "我想先把你的状况了解得充分一点，之后的方向会更清楚些";
  let input = user_input_details.value.trim();
  if (last_message == first_message && end) input = '暂时没有要说的了';
  //if (last_message == coping && end) input = coping_no;
  if (!input) { // no input
    if (end) end_details(); //move to next block if has end signal, otherwise just wait for user input
    return;
  }
  if (input != no_more_detail) {
    g_user_chat_history.details_input += '|' + input;
    saveUserChatLocal();
  }
  chat_messages.value.push(input);  // add user chat message to show on screen
  disableInput.value = true;
  user_input_details.value = ''; // reset input box
  scroll_to_bottom();

  let end_signal = (input.match(/没了/) && input.length <= 2) || input.match(/没有补充了/);  // handle input like "没了" “没有补充了”
  if (end_signal && input.length <= 6) end = 1;
//  let regex = new RegExp("[你？?该]"); // handle input contains "你"，“该” or “？”
  //let need_explain = regex.test(input);
//  let need_explain = regex.test(input)  &&  input.length <= 12;
//  if (need_explain) {
//    if (explained) end = 1;
//    else explained = true;
//  }

  // generate a response message
  let count = chat_messages.value.length;
  let duration = 0.5;
  if (last_message == first_message && 1==2) { //ask for coping first //1==2 to avoid
    await delay(duration);
    chat_messages.value.push(coping);
    input_prompt.value = "我做了：";
    ai_detail_no.value = coping_no;
  } else if (count == 4 && input == coping_no && 1==2) { // ask if user is OK upon getting coping_no as input //1==2 to avoid
    await delay(duration);
    chat_messages.value.push('你并没有做什么。那也是一种选择。\n多说一点吧');
    input_prompt.value = "";
    ai_detail_no.value = no_more_detail;
  } else if (!end) { // either a simple response or use ai_summarize
    input_prompt.value = "";
    ai_detail_no.value = no_more_detail;
    let variations = [">...", ">..."];//,"嗯","请继续说","嗯","嗯嗯", "嗯。\n还有吗？","嗯", "嗯。\n然后呢？","噢","嗯"];
      passive_response.value = variations.random();
    let response = passive_response.value; //need_explain ? explanation : passive_response.value;
    chat_messages.value.push(response);
    let summarize_ai = input.length >= 1 && input.length <= AI_INPUT_MAX;
    //summarize_ai = summarize_ai && !need_explain;
    if (summarize_ai) { // call ai_summarize only for certain indices
      g_summary_index++;
      summarize_ai = 1; //SUMMARY_INDICES.includes(g_summary_index);
    }
    console.log('g_user_chat_history:', g_user_chat_history)
    if (summarize_ai) {
      let lastChat = await getLastChatElement();
      blink_element(lastChat);
      let summary = await ai_summarize(input);
      unblink_element(lastChat);
      if (summary && !summary.startsWith("无法总结")) {
        // changed by Jun Wang 2023-07-17, based on 景山's new prompt: 模仿罗杰斯,最简短总结回应对方的话,只写回应的话语:{user_input}
        //response = summary.replaceAll('我', '你');
        response = summary.replace(/^回应(：|:)/, '');
        chat_messages.value[chat_messages.value.length - 1] = response;
      }
    }
  } else {
    end_details();
    return;
  }
  disableInput.value = false;
  scroll_to_bottom();
}

function end_details() {
  hide_element_by_id('div_input');
  show_feeling();
  saveUserChatHistory(); // save details_input
}

/* // old version
async function ai_more_details(end=0){ // get more details after user selected a leaf returned by ai
  let first_question = user_input_question.value.startsWith('你想再多说一点吗');
  let next_question = "面对这种情况，你做了什么吗？";
  let ai_cope = user_input_question.value == next_question;
  let more = ['嗯，我听到了。', '谢谢你告诉我。', '哦，原来是这样啊。', '哦，你经历了这些啊，我都听到了。'];
  let input = user_input_details.value.trim();
  if (input) {
    g_user_chat_history.details_input += '|' + input;
    //saveUserChatLocal();
  }
  if (input && input.length <= 6 && input.match(/^我?没/)) end = 1; // handle input like "没了" “没有补充了”
  //if (first_question && !g_user_chat_history.details_input.includes('我')) {// without mentioning 我
  if (first_question) { // just ask next question (应对) whether input mentions 我 or not
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = next_question;
    input_prompt.value = "我做了：";
    ai_detail_no.value = '我并没有做什么';
  }
  else if (ai_cope && end){
    g_user_chat_history.details_input += '|我并没有做什么。';
    //saveUserChatLocal();
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = '嗯，你并没有做什么。那你现在还好吗？';
    input_prompt.value = "";
    ai_detail_no.value = '没有特别想说的了';
  }
  else if (input && !end){
    input_prompt.value = "";
    ai_detail_no.value = '没有特别想说的了';
    user_input_details.value = ''; //reset
    let prompt = '';
    do {// make sure this prompt is not same as the last one
      prompt = more.random(); 
    } while(prompt == user_input_question.value);
    // call ai_summarize when input length [5,150] && input does not include 你 or ?
    let summarize_ai = input.length >= 5 && input.length <= AI_INPUT_MAX;
    let regex = new RegExp("[你？?]");
    summarize_ai = summarize_ai && !regex.test(input);
    if(summarize_ai){
      //g_ai_summary = 1;
      user_input_question.value = '我在听。。。';
      hide_element_by_id('div_details');
      blink_element("span_question");
      let summary = await ai_summarize(input);
      if (summary) prompt = '嗯，我听到你说：' + summary.replaceAll('我', '你');
      unblink_element("span_question");
      show_element_by_id('div_details');
      user_input_question.value = prompt;
      return;
    }
    // let summarize = input.length >= 8 && input.length <= 30 && !input.includes('你');
    // if (summarize) {
    //   prompt = prompt.replace('。', '：') + input.replaceAll('我', '你');
    // }
    show_element_animated(show_details_input_block);
    user_input_question.value = prompt;
    //ai_more_input.value = true;
  }
  else{
    hide_element_by_id('div_input');
    show_feeling();
    saveUserChatHistory(); // save start score & details_input
  }
}
*/

async function show_feeling() {
  g_user_chat_history.next = 'F'; // next block: feeling
  saveUserChatLocal();
  let details;
  details = g_user_chat_history.details_input;
  if (details.length>2500) {
    let options = {task: 'gpt_predict_leaf', subtask: 'summary_0', 'user_input': details};
    let {data, error} = await post_request(options);
    details = data;
    console.log('summarized details =',details);
  }
  if (g_user_chat_history.branch!='其他') {
    let ai_predictPromise = ai_predict(details); 
    await ai_predictPromise; // now wait for ai_predict to finish
    console.log('test x2 leaf=',g_user_chat_history.leaf);
  }
  if (g_user_chat_history.branch=='其他' || g_user_chat_history.leaf=='no_leaf') {
    ai_weak.value = true;}
  else{
      ai_weak.value = false;
  }
  console.log('test x3 ai_weak.value=',ai_weak.value);
  if (ai_weak.value) {
      console.log('test y1 before - details =',details);
      await ai_leaf(details); // call our own model to get quick return for feelings
      console.log('test x3 g_feelings =',g_feelings);
      let ai_feelingneedsWait=  ai_leaf(details, true); // call open ai model to get more verbose and accurate needs
      await ai_feelingneedsWait;
   } 
  else {
    ai_leaf(g_user_chat_history.leaf);//use leaf to find feeling needs etc
  }
  if (use_weak_feelings_path) show_element_animated(show_feeling_choices_block);
  else show_next_conversation_of_feeling();
}

function save_user_input(input) {
  if (!g_user_chat_history.details_input) g_user_chat_history.details_input = '';
  if (user_input_question.value == review_question) g_user_chat_history.details_input = input;
  else {
    let separator = g_ai_leaf ? '|' : ' ';
    g_user_chat_history.details_input += separator + input;
  }
  saveUserChatLocal();
}

async function finish_the_details() {
  //if (g_ai_leaf) {  // get more details after user selected a leaf returned by ai
  if (g_ai) {   //g_ai_leaf revised to g_ai to remove condition
    ai_more_details();
    return;
  }
  let first_question = user_input_question.value.startsWith(g_ai ? "你还好吗" : "说说具体情况");
  let second_question = "你可以讲得稍微具体一点吗？";
  if (!g_ai) second_question += "比如一个具体事件或细节?";
  let third_question = "面对这种情况，你做了什么吗？";

  let input = user_input_details.value.trim();
  save_user_input(input);
  let input_combined = g_user_chat_history.details_input;
  console.log('- user input details:', input_combined)

  if (user_input_question.value == review_question) {
    ai_require_input.value = g_ai && input_combined.length < AI_INPUT_MIN;
    if (!ai_require_input.value) hide_element_by_id('div_input');
    if (g_ai) {
      if (!ai_require_input.value) ask_ai(input_combined);
      else {
        blink_element('div_ai_require', 2);
      }
    } else {
      //combine_feelings_from_input(input_combined);
      show_next_conversation_of_feeling();
    }
    return;
  }

  let cut_off = g_ai ? 10 : 40;
  if (first_question && input.length < cut_off) { // first input less than cut_off
    show_element_animated(show_details_input_block);
    user_input_details.value = ''; //reset
    user_input_question.value = second_question;
  }
  // without mentioning 我
  else if (!g_ai && user_input_question.value != third_question && !input_combined.includes('我')) {
    if (user_input_question.value != review_question) {
      show_element_animated(show_details_input_block);
      user_input_details.value = ''; //reset
      user_input_question.value = third_question;
    }
  } else {
    user_input_details.value = g_user_chat_history.details_input; //reset
    //let regexno = new RegExp("[No]"); // handle output contains "No”
    // added by Jun Wang (2023-10-08) to handle the case when user input is too short
    if (g_user_chat_history.details_input.length < CUTOFF_OF_USER_INPUT_THAT_NEEDS_DETAILS) 
      review_question = review_question_that_needs_details;

    const is_related_to_mental_need = await ai_identifytrouble(g_user_chat_history.details_input)
    if (is_related_to_mental_need == 'No')
      review_question = review_question_for_trouble;

    user_input_question.value = review_question;

    if (!g_user_chat_history.details_input && g_ai && debug) { // input empty
      ai_example(); // get a random ai chat example
    }
  }
}

function combine_feelings_from_input(input) {
  console.log(' - g_feelings', g_feelings);
  //find feeling vocab from user input
  feeling_vocab.forEach(function (vocab) {
    if (input.includes(vocab)) {
      g_feelings_from_input.add(vocab);
    }
  });
  //combine to g_feelings
  g_feelings_from_input.forEach(function (feeling) {
    // check if input_feeling already in g_feelings
    let index = g_feelings.indexOf(feeling);
    if (index != -1) {// exists already: move to start
      g_feelings.unshift(g_feelings.splice(index, 1)[0]);
    } else { // new: insert to start
      g_feelings.unshift(feeling);
    }
  });
  console.log(' - user input feeling', g_feelings_from_input);
  console.log(' - g_feelings', g_feelings);
  dedup_feeling();
  g_feelings = g_feelings.slice(0, 6); //  at most 6 feelings
  if (use_weak_feelings_path || ai_weak.value) feeling_choices.value = g_feelings;
  console.log(' - g_feelings limited', g_feelings);
  g_ai_info.feelings = g_feelings;
  saveInfoLocal();
}

// check if one char feeling is dup with another feeling, and dedup e.g. 烦/烦躁， 急/着急 
// also remove similar feeling, e.g. 担忧/担心
function dedup_feeling() {
  let short_list = g_feelings.filter(item => item.length == 1);
  let long_list = g_feelings.filter(item => item.length > 1);
  short_list.forEach(function (short) { // check all one_char feelings
    long_list.forEach(function (long) { // check each of the remaining feeling word
      if (long.includes(short)) { // dup found
        g_feelings = g_feelings.filter(item => item != short); // dedup from g_feelings
      }
    });
  });

  let similar_list = [['担忧', '担心']];
  similar_list.forEach(function (pair) { // check all similar feelings
    if (g_feelings.includes(pair[0]) && g_feelings.includes(pair[1])) { // similar found
      g_feelings = g_feelings.filter(item => item != pair[0]); // dedup from g_feelings
    }
  });
}

function get_next_feeling() {
  first_feeling.value = g_next_feeling_index == -1;
  g_next_feeling_index += 1;
  if (g_feelings.length > g_next_feeling_index) {
    let feeling = g_feelings[g_next_feeling_index];
    console.log('- next feeling: ', feeling)
    //if (debug) g_next_feeling_index += 999;
    return feeling;
  } else {
    return null;
  }
}

function get_next_need() {
  g_next_need_index += 1;
  g_needs = g_ai_info.needs;
  console.log('- g_needs=: ', g_needs)
  if (g_needs.length > g_next_need_index) {
    let need = g_needs[g_next_need_index];
    console.log('- next need: ', need)
    if (ai_weak.value) need = update_need_sentence(need);
    //if (debug) g_next_need_index += 999;
    return need;
  } else {
    return null;
  }
}

function update_need_sentence(need) {
  let need_1 = need.trim();
  console.log('need1= ', need_1);
  let need0 = need_1.replace(/^\(\d+\)\s*/, '');
  console.log('need2= ', need0);  
  console.log('need0.length= ', need0.length);  
  let from_open_ai = need0.startsWith('我') || need0.length>7;
  return from_open_ai ? need0.replaceAll('我', '你') + '?' : need_vocab_to_sentence(need0);
}

function need_vocab_to_sentence(need) {
  if (need.endsWith('感')) return `你希望有${need}，是吗?`;
  if (need.includes('被')) return `你渴望${need}，是吗?`;
  if (need.startsWith('去') || need.startsWith('表达')) return `你是不是很想${need}?`;
  return `你是不是需要${need}?`;
}

function click_feeling(yes_no) {
  g_user_chat_history.cur_feeling = g_next_feeling_index;
  if (yes_no == 'yes') {
    g_feelings_yes.push(g_feeling_curr);
    hide_element_by_id('div_feeling');
    if (g_feelings_yes.length == 1) { // 1th feeling_yes
      g_user_chat_history.feelings_yes = [];
      g_user_chat_history.feeling_detail = {};
      feeling_detail_question.value = `关于${g_feeling_curr}，你想说点什么呢？`;//seems useless
    } else {
      feeling_detail_question.value = `关于${g_feeling_curr}，你想说什么？`;//seems useless
    }
    g_user_chat_history.feelings_yes.push(g_feeling_curr);
    show_feeling_detail_block.value = true;
    feeling_detail.value = ''; //reset
  } else {
    show_feeling_detail_block.value = false;
    show_next_conversation_of_feeling();
  }
  saveUserChatLocal();
}

function submit_feeling_detail() {
  show_feeling_detail_block.value = false;
  let detail = feeling_detail.value.trim();
  if (detail) {
    //g_user_chat_history.feeling_detail += `${g_feeling_curr}:${detail}|`;
    g_user_chat_history.feeling_detail[g_feeling_curr] = detail;
    saveUserChatLocal();
  }
  console.log('feeling detail ', g_user_chat_history.feeling_detail);
  if (use_weak_feelings_path || ai_weak.value) prime_feeling_detail();
  else show_next_conversation_of_feeling();
}

// leaf_weak: get feeling detail for prime_feelings only (excluding body sensation)
let g_prime_feeling_index = -1;
let g_prime_emotions = [];

function prime_feeling_detail() {
  hide_element_by_id('div_feeling_detail');
  g_feeling_curr = get_next_prime_feeling();
  feeling_curr.value = g_feeling_curr;
  if (g_feeling_curr == null) {
    body_ex();
  } else {
    g_user_chat_history.cur_feeling = g_prime_feeling_index;
    feeling_detail.value = ''; //reset
    show_feeling_detail_block.value = true;
    show_element_animated(show_feeling_detail_block);
    if (g_prime_feeling_index == 0) { // first prime_feeling
      g_user_chat_history.feeling_detail = {};
      feeling_detail_question.value = `关于“${g_feeling_curr}”，你想说点什么？`;
    } else {
      feeling_detail_question.value = `关于“${g_feeling_curr}”，你想说点什么？`;
    }
  }
}

function get_next_prime_feeling() {
  g_prime_feeling_index += 1;
  if (g_prime_emotions.length > g_prime_feeling_index) {
    return g_prime_emotions[g_prime_feeling_index];
  } else {
    return null;
  }
}

function reset_choices() {
  // reset choices to unchecked (needed for radio button but not ionic-button)
  // let choices = document.getElementsByClassName('choice');
  // [].forEach.call(choices, function (el) {el.checked = false; });
}

function show_next_conversation_of_feeling() {
  g_feeling_curr = get_next_feeling();
  feeling_curr.value = g_feeling_curr;
  if (g_feeling_curr == null) {
    console.log('- finish leaf node feelings, ask for body sensation');
    hide_element_by_id('div_feeling');
    hide_element_by_id('div_feeling_detail');
    g_user_chat_history.cur_feeling = 'body';
    saveUserChatLocal();
    show_element_animated(show_body_block);
  } else {
    show_element_animated(show_feelings_block);
    reset_choices();
  }
}

//leaf_weak: present feeling choices at once and get user's checked choices
function submit_feeling_choices() {
  let checked = checked_feelings.value;
  g_feelings_yes = Object.keys(checked).filter(key => checked[key]);
  console.log('Checked Feelings:', g_feelings_yes);
  hide_element_by_id('div_feeling_choices');
  g_user_chat_history.feelings_yes = [...g_feelings_yes];
  g_user_chat_history.cur_feeling = 'body';
  saveUserChatLocal();
  show_element_animated(show_body_block);
}

function submit_cbt_err_choices() {
  let checked = checked_cbt_err.value;
  g_cbt_err_yes = Object.keys(checked).filter(key => checked[key]);
  console.log('Checked cbt_err:', g_cbt_err_yes);
  hide_element_by_id('div_cbt_err_choices');
  g_user_chat_history.thoughts[g_thought_index]['cbt_yes']= [...g_cbt_err_yes];
    show_element_animated(show_thought_detail);
    //let err = has_next ? cbt_err_choices[id] : cbt_err_choices2[id];
    //cbt_question.value = `你认为这个想法存在的问题是：\n${err}`;
    //g_user_chat_history.thoughts[g_thought_index]['cbt_yes'] = err;
    saveUserChatLocal();
}

function click_body(yes_no) {
  console.log('body yes_no:', yes_no);

  if (yes_no == 'yes') {
    g_user_chat_history.cur_feeling = 'body_detail';
    saveUserChatLocal();
    show_body_detail();
  } else {
    ask_feeling_other();
  }
}

function show_body_detail() {
  hide_element_by_id('div_body');
  has_body_sensation.value = true;
  show_body_detail_block.value = true;
  show_element_sequenced("div_body_detail");
}

async function show_element_sequenced(div_name) {
  await nextTick();
  await delay(0.5);
  let div = document.getElementById(div_name);
  if (!div) return;
  let elements = div.getElementsByClassName('hidden');
  while (elements.length) {
    elements[0].classList.remove('hidden');
    elements = div.getElementsByClassName('hidden');
    await delay(debug ? 0.2 : 1.5);
    scroll_to_bottom();
  }
}

function submit_body_detail() {
  show_body_detail_block.value = false;
  let detail = body_detail.value.trim();
  g_user_chat_history.body_sensation = detail;
  saveUserChatLocal();
  console.log('body sensation:', g_user_chat_history.body_sensation)
  ask_feeling_other();
}

function ask_feeling_other() {
  g_user_chat_history.cur_feeling = 'other';
  hide_element_by_id('div_body');
  hide_element_by_id('div_body_detail');

  show_element_animated(show_feeling_other_block);
  console.log('feeling_chosen g_feelings_yes', g_feelings_yes)
  feeling_chosen.value = g_feelings_yes.join('，');

  // get examples for feeling_other: 
  // not already in decision tree/user input; not similar (exclude if contains same char)
  shuffleArrayInPlace(feeling_example);
  let hint = [];
  feeling_example.forEach(function (vocab) {
    if (hint.length == 3) return;
    if (!g_feelings.includes(vocab)) { // not already
      if (!vocab_has_char_set(g_feelings.join(''), vocab)) { // vocab does not contain any char in g_feelings
        if (!vocab_has_char_set(hint.join(''), vocab)) { // vocab does not contain any char in hint list so far
          hint.push(vocab);
        }
      }
    }
  });
  feeling_hint.value = hint.join(' ');

  show_element_sequenced("div_feeling_other");
}

function vocab_has_char_set(char_set, vocab) {
  let similar = false;
  for (const c of vocab) {// go through each char to check if similar
    if (char_set.includes(c)) {
      similar = true;
      return;
    }
  }
  return similar;
}

function shuffleArrayInPlace(array) {
  for (let i = 0; i < array.length; i++) {
    let k = Math.floor(Math.random() * (i + 1)); // [0, i]
    //swap the ith and kth element
    let temp = array[i];
    array[i] = array[k];
    array[k] = temp;
  }
}

function click_feeling_other(yes_no) {
  if (yes_no == 'yes') {
    hide_element_by_id('feeling_other_choice');
    show_feeling_other_input_block.value = true;
  } else {
    //show_next_conversation_of_need(true);
    get_feeling_order();
  }
}

function submit_feeling_other() {
  show_feeling_other_block.value = false;
  save_feeling_other();
  //show_next_conversation_of_need(true);
  get_feeling_order();
}

function save_feeling_other() {
  let detail = feeling_other.value.trim();
  if (detail) {
    // split by punctuations/space, and remove empty element
    detail = detail.split(/[\s，；、。!？?.,/#!$%^&*;:{}=\-_`~()]+/).filter(element => element);
    g_user_chat_history.feeling_other = detail.slice(0, 3); // get first 3
    saveUserChatLocal();
    console.log('feeling other:', g_user_chat_history.feeling_other)
  }
}

function get_feeling_order() {
  g_user_chat_history.cur_feeling = 'order';
  saveUserChatLocal();
  if (has_body_sensation.value) {
    let body = body_detail.value ? `身体上的不舒服：${body_detail.value}` : '身体上的不舒服';
    g_feelings_yes.push(body);
  }
  if (g_user_chat_history.feeling_other) {
    //g_feelings_yes = g_feelings_yes.concat(g_user_chat_history.feeling_other);
    g_feelings_yes = [...new Set([...g_feelings_yes, ...g_user_chat_history.feeling_other])]; // concat and remove dups
    console.log('g_feelings_yes', g_feelings_yes);
  }
  if (g_feelings_yes.length > 3) {
    feeling_order_prompt.value = `${user_alias.value}，这些感受中，你觉得最强烈的是？`;
    feeling_choices.value = [...g_feelings_yes];
    hide_element_by_id('div_feeling_other');
    show_element_animated(show_feeling_order_block);
  } else {
    g_prime_feelings = g_feelings_yes;
    show_body_ex();
  }
}

async function show_body_ex() {
  hide_element_by_id('div_feeling_other');
  hide_element_by_id('div_feeling_order');
  if (g_feelings_yes.length) g_user_chat_history.feelings_yes = g_feelings_yes; // ordered if more than 3
  if (use_weak_feelings_path || ai_weak.value) {
    g_user_chat_history.next = 'D'; // next block: feeling detail
    saveUserChatLocal();
    g_prime_emotions = [...g_prime_feelings.filter(item => !item.startsWith("身体上的不舒服"))];
    prime_feeling_detail();
  } else body_ex();
}

function body_ex() {
  g_user_chat_history.cur_feeling = '';
  g_user_chat_history.next = 'B'; // next block: body ex or imagine
  saveUserChatLocal();
  saveUserChatHistory();
  console.log('feelings_yes ', g_user_chat_history.feelings_yes);

  if (has_body_sensation.value) {
    loadAudio();
    show_element_animated(show_body_ex_block);
    show_element_sequenced("div_body_ex");
    // await delay(2);
    // show_element_by_id('body_ex_bg');    
    // show_element_by_id('ex_audio_btn');
    // await delay(10);
    // show_element_by_id('body_ex_next');
  } else show_imagine(); //show_next_conversation_of_need(true);
}

function toggleNatureAudio(checked) {
  let elem = document.getElementById('natureSelect');
  if (checked) {
    g_nature_audio.play();
    elem.removeAttribute('disabled');
  } else {
    g_nature_audio.pause();
    elem.setAttribute('disabled', '');
  }
}

function playNatureSound() {
  // if newChat() triggers ionChange, simply return
  if (!show_cool_down_block.value) return;

  if (g_nature_audio) {
    g_nature_audio.pause();
    g_nature_audio.currentTime = 0;
  }
  g_nature_audio = new Audio(`/assets/audio/${nature.value}.mp3`);
  g_nature_audio.play();

  //g_nature_audio.loop = true;
  g_nature_audio.addEventListener("timeupdate", function () {
    if (this.currentTime >= this.duration - 2) { // avoid mp3 delay in loop
      this.currentTime = 2;
      this.play();
    }
  });
}

function click_feeling_order(i) {
  console.log('feelings_yes ', g_feelings_yes);
  g_prime_feelings.push(feeling_choices.value[i]);
  if (g_prime_feelings.length < 3) {
    reset_choices();
    feeling_choices.value.splice(i, 1);
    let prime = g_prime_feelings.join('、');
    feeling_order_prompt.value = `嗯，你感到${prime}。除此之外，你觉得较强烈的感受是？`;
    show_element_animated(show_feeling_order_block);
  } else {
    console.log('prime feelings ', g_prime_feelings)
    for (let j = 2; j >= 0; j--) {//reverse loop through g_prime_feelings
      let feeling = g_prime_feelings[j];
      g_feelings_yes = g_feelings_yes.filter(item => item !== feeling);
      g_feelings_yes.unshift(feeling); // move to front
    }
    // console.log('feelings_yes ', g_feelings_yes);
    show_body_ex();
  }
}

async function show_imagine() {
  g_user_chat_history.next = 'I'; // next block: imagine
  stopAudio();
  hide_element_by_id('div_body_ex');
  show_element_animated(show_imagine_block);
  show_element_sequenced("imagine_guide");
  await delay(debug ? 0 : 12);
  show_element_by_id('imagine_choice');
}

function click_imagine(yes_no) {
  if (yes_no == 'yes') {
    hide_element_by_id('imagine_choice');
    show_imagine_detail_block.value = true;
  } else {
    show_next_conversation_of_need(true);
  }
}

function submit_imagine_detail() {
  show_imagine_detail_block.value = false;
  let detail = imagine_detail.value.trim();
  if (detail) {
    g_user_chat_history.cur_feeling = 'new';
    g_user_chat_history.imagine_detail = detail;
    saveUserChatLocal();
    console.log('imagine detail:', detail)
    show_feeling_new();
  } else {
    show_next_conversation_of_need(true);
  }
}

function show_feeling_new() {
  hide_element_by_id('imagine_guide');
  ask_new_feeling.value = true;
  let feelings = g_prime_feelings.filter(item => !item.startsWith("身体上的不舒服"));
  if (feelings.length) prime_emotions.value = feelings.join('，');
}

function click_feeling_new(yes_no) {
  hide_element_by_id('feeling_new_choice');
  if (yes_no == 'yes') {
    show_new_feeling_block.value = true;
  } else {
    show_next_conversation_of_need(true);
  }
}

function submit_new_feeling() {
  let detail = feeling_new.value.trim();
  if (detail) {
    g_user_chat_history.feeling_new = detail;
    saveUserChatLocal();
    console.log('feeling new:', detail)
  }
  show_next_conversation_of_need(true);
}

function click_need(yes_no) {
  console.log('need yes_no:', yes_no);
  need_detail_confirm.value = false;
  g_user_chat_history.cur_need = g_next_need_index;
  if (yes_no == 'yes') {
    g_need_curr = g_need_curr.replace(/^你是不是/, '你');
    g_need_curr = g_need_curr.replaceAll('？', '');
    g_need_curr = g_need_curr.replaceAll('?', '');
    g_need_curr = g_need_curr.replace(/[，。]\s?不?[是对][吗吧].*/, '');
    g_need_curr = g_need_curr.replace(/([^是对])吗/, '$1');
    need_repeat.value = g_need_curr;
    g_needs_yes.push(g_need_curr);
    if (!g_user_chat_history.needs_yes) {
      g_user_chat_history.needs_yes = [];
      g_user_chat_history.need_detail = [];
    }
    g_user_chat_history.needs_yes.push(g_need_curr);

    hide_element_by_id('need_choice');
    show_need_detail_block.value = true;
    need_detail.value = ''; //reset
  } else {
    show_need_detail_block.value = false;
    show_next_conversation_of_need();
  }
  saveUserChatLocal();
}

Array.prototype.random = function () {
  return this[Math.floor((Math.random() * this.length))];
}

function submit_need_detail() {
  show_need_detail_block.value = false;
  let detail = need_detail.value.trim();
  if (detail && !detail.includes('你')) {
    need_repeat.value += `\n${detail.replaceAll('我', '你')}`;
  }
  g_user_chat_history.need_detail.push(detail);
  saveUserChatLocal();
  hide_element_by_id('div_need_prompt');
  need_detail_confirm.value = true;
  let variations = ["我听到了", "我明白了", "你觉察到", "这是你的觉察"];
  need_repeat_start.value = variations.random();
  //show_element_sequenced('div_need_confirm');
  console.log('need detail ', g_user_chat_history.need_detail);
  show_next_conversation_of_need();
}

async function show_next_conversation_of_need(first_round = false) {
  if (first_round) {
    delete g_user_chat_history.cur_feeling;
    g_user_chat_history.next = 'N'; // set next to need
    saveUserChatLocal();
    saveUserChatHistory(); // save imagine_detail, feeling_new
    hide_element_by_id('div_imagine');
    if (g_prime_feelings.length) {
      let first = g_prime_feelings[0];
      if (!first.startsWith('身体上的不舒服')) prime_feeling.value = first;
      else if (g_prime_feelings.length > 1) prime_feeling.value = g_prime_feelings[1];
    }
  }
  first_need.value = first_round;
  g_need_curr = get_next_need();
  if (g_need_curr != null) {
    if (need_detail_confirm.value) {
      await show_element_animated(need_detail_confirm);
      await delay(2);
      show_element_by_id("div_need_prompt");
      show_element_by_id("need_choice");
    } else show_element_animated(show_needs_block);

    do_you_need_question.value = g_need_curr;
    if (first_round && prime_feeling.value) {
      do_you_need_question.value = do_you_need_question.value.replace(/^你是不是/, '你');
    }
    reset_choices();
  } else {
    if (first_round) { // data missing: the feelings data is available but the needs data is missing
      do_you_need_question.value = `数据缺失! 有"感受"数据，但没有"需要"数据`
    }
    console.log('- done with needs');
    delete g_user_chat_history.cur_need;
    g_user_chat_history.next = 'R'; // set next to recap
    saveUserChatLocal();
    saveUserChatHistory(); // save needs
    if (need_detail_confirm.value) {
      await show_element_animated(need_detail_confirm);
      await delay(3);
    }
    hide_element_by_id('div_need');
    show_recap();
  }
}

function set_recap_sentences() {
  let html_feelings = g_prime_feelings.join("| - ");
  let new_feelings = g_user_chat_history.feeling_new;
  if (new_feelings) html_feelings += html_feelings ? `| - ${new_feelings}` : new_feelings;
  let html_needs = g_needs_yes.join("| - ");
  //html_needs = html_needs.replaceAll('？', '');
  g_feeling_need_recap = html_feelings ? "|你感到| - " + html_feelings : '';
  if (html_feelings && html_needs) g_feeling_need_recap += "|| 因为";
  if (html_needs) g_feeling_need_recap += "| - " + html_needs;
}

async function show_recap() {
  set_recap_sentences();
  let sentences = [`我看到了你的积极觉察。`];
  if (g_feeling_need_recap) sentences.push("现在我们稍微停下来，回顾一下做个小总结");
  show_needs_block.value = false;
  show_element_animated(show_recap_block);

  sentences = sentences.concat(g_feeling_need_recap.split('|'))
  recaps.value = sentences;

  for (let i = 0; i < sentences.length; i++) {
    await delay(1);
    show_element_by_id(`recap${i}`);
    scroll_to_bottom();
  }
  await delay(2);
  show_element_by_id("recap_choice");
  scroll_to_bottom();
}

function click_cooldown(yes_no) {
  g_user_chat_history.cooldown = yes_no;
  saveUserChatLocal();
  saveUserChatHistory(); // save cooldown
  if (yes_no == 'Y') {
    show_recap_block.value = false;
    show_the_cool_down_part();
  } else {
    show_positive();
  }
}

async function show_positive() {
  g_user_chat_history.next = 'P';  // positive
  saveUserChatLocal();
  hide_element_by_id('div_recap');
  hide_element_by_id('div_cooldown');
  if (g_nature_audio) {
    g_nature_audio.pause();
    g_nature_audio.currentTime = 0;
    if (nature.value != g_saved_nature) {
      updateProfile({nature: nature.value});
    }
  }

  // randomly select a varation, make sure it's not the one used during last session
  let name = user_alias.value;
  let feelings = g_prime_feelings.join('、') || '困扰';
  let variations = [[`${name}，听了你的困境和状况`, '我看到你面临这样的挑战或问题，勇敢面对',
    '积极思考和尝试，努力寻找解决的办法(包括来找我谈话)', '你是怎么做到的？', '(停下来想想，不用回答)'],
    [`${name}，你感到${feelings}，是很不容易的`,`而你找我谈话，去勇敢面对问题`,  
      '你在努力寻找方法，朝着你想要的方向走',`你是怎么做到的？`, '(停下来想想，不用回答)'],
    [`${name}，你真的很不容易`,`今天你感到${feelings}`,  
      '你能勇敢地面对','积极地寻找方向','你是怎么积聚起力量的？', '(停下来想想，不用回答)'],
    [`${name}，我能感受到你的不容易，辛苦了！`,`你感到${feelings}之后，能够勇敢面对困难`, '也跟我探索如何面对', 
      `你是怎么找到勇气，找到努力的力量的？`, '(停下来想想，不用回答)'],
    [`${name}，你感到${feelings}。`, '其实，真的很好奇', '通常很多人是回避烦恼或问题的',
      '而你却在勇敢面对，积极寻求帮助，认真探索', '你是怎么做到的呢？', '(停下来想想，不用回答)']];
  let key = 'pos';
  let savedIndex = (localStorage && localStorage.getItem(key)) || 0, randomIndex = 0;
  do {
    randomIndex = Math.floor(Math.random() * variations.length);
  } while (randomIndex == savedIndex);
  //positives.value = variations[randomIndex];
  let details;
  details = g_user_chat_history.details_input; 
  console.log('details= ', details);
  try {
    let options2 = {task: 'gpt_predict_leaf', subtask: 'cbt_0', 'user_input': details};
    let {data, error} = await post_request(options2);
    data.unshift('现在，几位协助我工作的梅花鹿出场，轮流发言，每人一句：')
    positives.value = data;
    console.log('positives.value= ', positives.value);
    g_ai_info.positive = positives.value;
  } catch (error) {
    console.log(error.message);
    return null;
  }
  try {
    let options1 = {task: 'gpt_predict_leaf', subtask: 'cbt_1', 'user_input': details};
    let {data, error} = await post_request(options1);
    thought_examples.value = data;
    g_ai_info.CBT_examples = thought_examples.value;
    console.log('cbt thoughts =',thought_examples.value);
  } catch (error) {
    console.log(error.message);
    return null;
  }
  localStorage.setItem(key, randomIndex);

  show_positive_block.value = true;
  show_element_sequenced('div_positive');
}

function show_otherside() {
  hide_element_by_id('div_positive');
  if (otherside.value) {
    //show_element_animated(show_otherside_block);
    g_user_chat_history.next = 'O';  // set next to otherside
    saveUserChatLocal();
    show_otherside_block.value = true;
    show_element_sequenced('otherside_init');
  } else show_cbt();
}

function show_cbt() {
  g_user_chat_history.next = 'T'; // set next to thought
  delete g_user_chat_history.cur_otherside;
  saveUserChatLocal();
  hide_element_by_id('div_otherside');
  show_element_animated(show_cbt_block);
  show_element_sequenced('div_cbt');
}

function click_otherside(yes_no) {
  if (yes_no == 'yes') {
    g_user_chat_history.cur_otherside = 'Y';
    start_otherside_input();
    saveUserChatLocal();
  } else {
    hide_element_by_id('div_otherside');
    show_cbt();
  }
}

async function start_otherside_input() {
  g_user_chat_history.otherside = {};
  hide_element_by_id('otherside_init');
  show_element_animated(show_otherside_input);
  await show_element_sequenced('div_otherside_input');
  // use 'invisible' instead of 'hidden' so textarea placeholder text will display properly
  // (if hidden, dimensions of autogrow textarea cannot be calculated)
  document.getElementById('otherside_text').classList.remove('invisible');
}

function submit_otherside() {
  g_user_chat_history.cur_otherside = g_otherside_index;
  let input = otherside_input.value.trim();
  if (input) {
    //g_user_chat_history.otherside.push(input); // save empty input too, to correspond to prompt
    let prompt = g_otherside[g_otherside_index] || ''; // no prompt, set empty string as key
    g_user_chat_history.otherside[prompt] = input; // save as dictionary key-value pair (prompt:input)
  }
  show_next_otherside_prompt();
  saveUserChatLocal();
}

function get_next_otherside() {
  g_otherside_index += 1;
  if (g_otherside.length > g_otherside_index) return g_otherside[g_otherside_index];
  else return null;
}

function show_next_otherside_prompt() {
  let first = g_otherside_index == -1;
  otherside_prompt.value = get_next_otherside();
  if (otherside_prompt.value) {
    if (first) {
      hide_element_by_id('otherside_start');
      otherside_prompt.value = '在我看来，' + otherside_prompt.value;
      if (otherside_input.value) {
        otherside_prompt.value = `${user_alias.value}, 谢谢你尝试换位思考。\n${otherside_prompt.value}`;
      }
    }
    otherside_input.value = ''; //reset
  } else { // done with otherside perspective
    show_otherside_input.value = false;
    show_element_sequenced('otherside_end');
    if (Object.keys(g_user_chat_history.otherside).length) saveUserChatHistory(); // save otherside
  }
}

function click_cbt(yes_no) {
  if (yes_no == 'yes') {
    g_user_chat_history.cur_cbt = 'Y';
    saveUserChatLocal();
    show_thoughts();
  } else {
    show_goal();
  }
}

function show_thoughts() {
  hide_element_by_id('cbt_choice');
  show_element_animated(show_thoughts_input);
  show_element_by_id('skip_cbt');
}

function submit_thoughts() {
  hide_element_by_id('div_cbt_init');
  save_thought(thought1);
  save_thought(thought2);
  save_thought(thought3);
  saveUserChatLocal();
  console.log('thoughts ', g_user_chat_history.thoughts);

  check_thought.value = true;
  show_next_cbt_question();
}

function save_thought(name) {
  let thought = name.value.trim();
  if (thought) {
    if (!g_user_chat_history.thoughts) {
      g_user_chat_history.thoughts = [];
    }
    g_user_chat_history.thoughts.push({'thought': thought, 'cbt_yes': '', 'correction': '', 'details': []});
  }
}

function get_next_thought() {
  g_thought_index++;
  g_user_chat_history.cur_cbt = g_thought_index;
  let thoughts = g_user_chat_history.thoughts;
  if (thoughts && g_thought_index < thoughts.length) return thoughts[g_thought_index].thought;
  else return null;
}

function submit_thought_correction() {
  let thought = g_user_chat_history.thoughts[g_thought_index];
  //thought['details'].push(thought_err_detail.value.trim());
  thought['correction'] = thought_correction.value.trim();
  saveUserChatLocal();
  show_thought_detail.value = false;
  cbt_choice_index.value = 0;
  thought_err_detail.value = '';
  thought_correction.value = '';
  show_next_cbt_question();
}

function submit_cbt_answer() {
  let detail = g_user_chat_history.thoughts[g_thought_index]['details'];
  detail.push(cbt_answer.value.trim());
  saveUserChatLocal();
  cbt_answer.value = ''; // reset
  show_next_cbt_question();
}

function show_thought_init(first) {
  if (first) thought_init.value = `你的第一个想法`;
  else thought_init.value = ``;
}

function show_next_cbt_question() {
  let count = cbt_questions.length;
  g_cbt_question_index++;
  console.log('cbt_question_index ', g_cbt_question_index, cbt_choice_index.value);
  // user chose a thought err for first question, skip the related second question
  if (g_cbt_question_index == 1 && cbt_choice_index.value == 0) {
    g_cbt_question_index++;
  }
  let first = g_thought_index == -1;
  if (g_cbt_question_index >= count || first) {
    let thought = get_next_thought();
    if (thought) {
      thought_curr.value = `${g_thought_index + 1}：${thought}`;
      g_cbt_question_index = g_cbt_question_index % count;
      show_thought_init(first);
      show_element_animated(show_thoughts_question);
      cbt_choice_index.value = 2;
    } else { // all thoughts examined
      console.log(g_user_chat_history.thoughts);
      hide_element_by_id('div_cbt');
      g_user_chat_history.next = 'G';
      saveUserChatLocal();
      next_cbt_general();
    }
  } else {
    show_element_animated(check_thought);
  }
  cbt_question.value = cbt_questions[g_cbt_question_index];
}

function next_cbt_general() {
  g_user_chat_history.cur_cbt = cbt_general_index;
  saveUserChatLocal();
  cbt_general_index += 1;
  if (cbt_general_questions.length > cbt_general_index) {
    cbt_general_question.value = cbt_general_questions[cbt_general_index];
    show_element_animated(show_cbt_general);
  } else {
    console.log("cbt_general", g_user_chat_history.cbt_general);
    show_behavior();
  }
}

function submit_cbt_general() {
  if (!g_user_chat_history.cbt_general) g_user_chat_history.cbt_general = [];
  g_user_chat_history.cbt_general.push(cbt_general_answer.value.trim());
  saveUserChatLocal();
  cbt_general_answer.value = ''; // reset
  next_cbt_general();
}

// function click_thought(yes_no) {
//   if (yes_no=='yes') {
//     cbt_choice_index.value = 2;
//   }
//   else{
//     cbt_choice_index.value = 0;
//   }
//   show_next_cbt_question();
// }

function click_thought_err(id, has_next) {
  console.log('thought err', id);
  if (id == -2) {
    cbt_choice_index.value = 2;
    g_cbt_question_index -= 2;
    show_next_cbt_question();
  } else if (id == -1) {
    show_thought_detail.value = false;
    cbt_choice_index.value = has_next ? 3 : 0;
    show_next_cbt_question();
  } else {
    cbt_choice_index.value = 4;
    show_element_animated(show_thought_detail);
    let err = has_next ? cbt_err_choices[id] : cbt_err_choices2[id];
    cbt_question.value = `你认为这个想法存在的问题是：\n${err}`;
    g_user_chat_history.thoughts[g_thought_index]['cbt_yes'] = err;
    saveUserChatLocal();
  }
}

function show_goal() {
  hide_element_by_id('skip_cbt');
  hide_element_by_id('div_cbt');
  hide_element_by_id('div_cbt_general');
  show_element_animated(show_goal_block);
  delete g_user_chat_history.cur_cbt;
  g_user_chat_history.next = 'L'; // set next to goal
  //if (g_user_chat_history.thoughts || g_user_chat_history.cbt_general) saveUserChatHistory(); //save cbt
  saveUserChatLocal();
}

function show_exception() {
  hide_element_by_id('div_goal');
  show_element_animated(show_exception_block);
  delete g_user_chat_history.goal;
  g_user_chat_history.next = 'X'; // set next to exception
  if (g_user_chat_history.goal) saveUserChatHistory(); //save goal
  saveUserChatLocal();
}

function show_behavior() {
  hide_element_by_id('div_exception');
  hide_element_by_id('skip_cbt');
  hide_element_by_id('div_cbt');
  hide_element_by_id('div_cbt_general');
  show_element_animated(show_behavior_block);
  delete g_user_chat_history.cur_cbt;
  delete g_user_chat_history.exception;
  g_user_chat_history.next = 'H'; // set next to behavior
  if (g_user_chat_history.thoughts || g_user_chat_history.cbt_general) saveUserChatHistory(); //save cbt
  if (g_user_chat_history.exception) saveUserChatHistory(); //save exception
  saveUserChatLocal();
}

function show_advice() {
  hide_element_by_id('div_behavior');
  show_element_animated(show_advice_block);
  delete g_user_chat_history.behavior;
  g_user_chat_history.next = 'A'; // set next to advice/angle
  if (g_user_chat_history.behavior) saveUserChatHistory(); //save behavior
  saveUserChatLocal();
}

function submit_advice() {
  hide_element_by_id('div_advice_init');
  let input = advice_input.value.trim();
  if (input) {
    g_user_chat_history.angle_new = input;
    advice_input_feedback.value = "谢谢你愿意尝试用新的观点和角度来看问题。\n你真是一个愿意敞开自己，允许并接纳不同观点和角度的人。"
  } else {
    advice_input_feedback.value = `哦，暂时没有新的观点和启发，没关系。\n\n今天的你能够坦诚表达自己，就是前进了一步。`;
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save angle_new
  advice_response.value = true;
  show_element_sequenced('div_advice_response');
}

function submit_goal() {
  hide_element_by_id('div_goal1');
  let input = goal_input.value.trim();
  if (input) {
    g_user_chat_history.goal = input;
  } else { g_user_chat_history.goal = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save goal
  show_exception_block.value = true;
  show_element_sequenced('show_goal_block');
}

function submit_exception() {
  hide_element_by_id('div_exception1');
  let input = exception_input.value.trim();
  if (input) {
    g_user_chat_history.exception = input;
  } else { g_user_chat_history.exception = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save exception
  show_behavior_block.value = true;
  show_element_sequenced('show_exception_block');
}

function submit_behavior() {
  hide_element_by_id('div_behavior1');
  let input = behavior_input.value.trim();
  if (input) {
    g_user_chat_history.behavior = input;
  } else { g_user_chat_history.behavior = '';
  }
  saveUserChatLocal();
  saveUserChatHistory(); // save behavior
  show_advice_block.value = true;
  show_element_sequenced('show_behavior_block');
}

function show_end_score() {
  g_user_chat_history.next = 'S'; // end score
  saveUserChatLocal();
  hide_element_by_id('div_advice');
  show_element_animated(show_end_score_block);
}

async function ai_example() {
  try {
    let options = {task: 'fetch_chat_example', 'index': Math.floor(Math.random() * 3001),};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    console.log('ai example', data);
    if (error) throw error;
    if (!data) throw new Error('no ai example available');
    user_input_details.value = data.start + data.continue;
  } catch (error) {
    console.log(error.message);
  }
}

async function ask_ai(input) {
  await saveUserChatHistory(true); // insert first input
  let ai_predictPromise = ai_predict(input.slice(0, AI_INPUT_MAX)); //callOpenAI(input);
  show_ai_block.value = true;
  await show_element_sequenced('ai_warmup'); // run this immediately
  await ai_predictPromise; // now wait for ai_predict to finish
  show_leaf_choices();
}

async function ai_predict(input) {
  try {
    //let options = {task: 'predict', 'user_input': input};
    let subtask1 = 'leaf_2';
    switch (g_user_chat_history.branch) {
      case '婚姻 恋爱 育儿 家庭': subtask1 = 'leaf_1'; break;
      case '自我状态': subtask1 = 'leaf_2'; break;
      case '学业 工作': subtask1 = 'leaf_3'; break;
      case '性': subtask1 = 'leaf_4'; break;
      default: subtask1 = 'leaf_2';
    } 
    let options = {task: 'gpt_predict_leaf', subtask: subtask1, 'user_input': input};
    let {data, error} = await post_request(options);
    console.log('ai result', data, error);
    g_user_chat_history.leaf = data;
    if (error) throw error;
    if (!data || !(data instanceof Array)) throw new Error('no ai predict available');
    g_ai_info.leaf_choices = data;
    saveInfoLocal();
    leaf_choices.value = data;
  } catch (error) {
    console.log(error.message);
  }
}

async function show_leaf_choices() {
  if (!leaf_choices.value.length) {
    ai_weak.value = true;
    show_ai_result.value = false;
    ai_weak_placeholder.value = "";
  } else {
    show_element_animated(show_ai_result);
  }
}

async function  ai_leaf(leaf_text, from_open_ai = false) {
  //hide_element_by_id('div_ai_choice');
  //hide_element_by_id('div_ai');
  //show_element_animated(show_start_score_block);

  let ai_weak_task = from_open_ai ? 'summarize_feelings_and_needs' : 'get_feelings_and_needs_only';
  let task = ai_weak.value ? ai_weak_task : 'get_feelings_needs_etc';
  if (ai_weak.value) leaf_text = leaf_text.slice(0, AI_INPUT_MAX);
  //if (ai_weak_task=='summarize_feelings_and_needs' && g_feelings && g_needs) leaf_text = leaf_text+" [possible NEEDS (NOT FEELINGS) points:"+g_needs+"]";
  try {
    console.log('z0 ai update', leaf_text);
    let options = {task: task, 'user_input': leaf_text};
    let {data, error} = await post_request(options);
    console.log('z1 ai leaf', data, error);
    if (error) throw error;
    if (!data) throw new Error('no ai leaf available');
    g_ai_leaf = true;
    if (ai_weak.value) { 
      // called twice
      // 1. get feelings & needs from our own model
      // 2. update needs from open_ai and update feelings in case our own model is unreachable
      if (!g_feelings) {
        g_feelings = data['feelings'];
      }
      else if (data['feelings']) {
        g_feelings = g_feelings.slice(0, 4).concat(data['feelings']); //combine own model prediction with gpt
        g_feelings = [...new Set(g_feelings)];  //remove duplicate
        g_feelings = g_feelings.filter(item => item.length <= 2);
      }     
      g_needs = data['needs'];
      g_ai_info.feelings = g_feelings;
      g_ai_info.needs = g_needs;
      console.log('z3 ai g_feelings', g_feelings);
      console.log('z4 ai g_needs', g_needs);
      saveInfoLocal();
      //if (from_open_ai) return; //no return as using feelings from GPT 
    } else {
      get_leaf_info(data);
    }
    g_feelings = g_feelings.slice(0, 6);
    if (use_weak_feelings_path || ai_weak.value) feeling_choices.value = g_feelings;
    //combine_feelings_from_input(g_user_chat_history.details_input);
  } catch (error) {
    console.log(error.message);
  }
  saveUserChatHistory(); // update chat history: leaf
}

async function ai_branch(branch_text) {
  hide_element_by_id('div_branch_choice');
  show_more_details();
  await saveUserChatHistory(true); // insert branch choice
}

/*async function ai_summarize(input) {
  try {
    let options = {task: 'summarize', 'user_input': input};
    let {data, error} = await post_request(options);
    //let {data, error} = await api.ai_req(options);
    console.log('ai summarize', data, error);
    if (error || !data) return null;
    return data.summary;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}*/

async function ai_summarize(input) {
  g_user_chat_history.details_input = g_user_chat_history.details_input.replace(/^undefined\|/, '');
  //console.log('test1',input_details);
  //console.log('test2',input);
  try {
    let prompt_flag;
    if (!g_user_chat_history.multi_turn_chat_seq) {
      let input_details = g_user_chat_history.details_input; // + ". " + input;
      g_user_chat_history.multi_turn_chat_seq = [input_details];
      prompt_flag = 1;
    } else {
      g_user_chat_history.multi_turn_chat_seq.push(input);
      prompt_flag = 2;
    }

    /*let options = {
      task: 'gpt_multi_turn_chat', //'gpt_shudong'
      prompt_flag: prompt_flag, //subtask:'input_1' etc
      chat_seq: g_user_chat_history.multi_turn_chat_seq //list of 1 or 3 items
    };*/
    let options = {
      task: 'gpt_shudong', 
      subtask: 'input_1',
      user_input: input, 
      g_chat_id: g_chat_id.toString()
    };
    let {data, error} = await post_request(options);
    console.log('options', options);//('ai summarize', data, error);

    //let gpt_response_text = data.gpt_multi_turn_chat;
    let gpt_response_text = data.gpt_shudong;
    if (gpt_response_text.includes("心理咨询") && gpt_response_text.length<20) {
      gpt_response_text = "我会用我的心理学能力尽力帮助你，你也可以另外寻求专业心理咨询师的帮助"
    }
    gpt_response_text = gpt_response_text.replace(/<<<|>>>/g, '');
    console.log('GPT response:', gpt_response_text)

    if (error || !data) return null;

    g_user_chat_history.multi_turn_chat_seq.push(gpt_response_text);
    return gpt_response_text;

  } catch (error) {
    console.log(error.message);
    return null;
  }
}

async function ai_identifytrouble(input) {
  await saveUserChatHistory(true); // insert first input

  try {
    console.log('ai gpt_input:', input);
    let options = {task: 'gpt_is_related_to_mental_need', 'user_input': input};
    let {data, error} = await post_request(options);
    console.log('ai gpt_is_related_to_mental_need', data, error);
    if (error || !data) return null;
    return data.gpt_is_related_to_mental_need;
  } catch (error) {
    console.log(error.message);
    return null;
  }
}

async function click_ai_choice(choice) {
  console.log('click ai choice', choice);
  if (choice == -1) {
    ai_weak.value = true;
    await delay(0.5);
    scroll_to_bottom(500);
    return;
  }
  g_user_chat_history.leaf = choice;
  saveUserChatLocal();
  ai_weak.value = false;
  ai_leaf(choice);
}

async function click_branch_choice(choice) {
  console.log('click branch choice', choice);
  g_user_chat_history.branch = choice;
  saveUserChatLocal()
  ai_branch(choice);
}

async function click_effect_choice(choice) {
  console.log('click effect choice', choice);
  g_user_chat_history.effect = choice;
  saveUserChatLocal()
  g_user_chat_history.next = 'K';
  show_feedback();
}

function submit_ai_weak() {
  let input = ai_weak_topic.value.trim();
  console.log('ai weak topic', input);
  if (!input) return;
  g_user_chat_history.leaf_weak = input;
  saveUserChatLocal();
  input += ' ' + g_user_chat_history.details_input;
  //show_element_by_id('ai_choice_msg');
  ai_leaf(input); // call our own model to get quick return for feelings
  ai_leaf(input, true); // call open ai model to get more verbose and accurate needs
}

var g_has_already_created_chat_history_record = false;
async function saveUserChatHistory(insert_new = false, ended = false) {
  console.log('save chat history', g_user_chat_history);
  //if (debug) return;
  //console.log(g_chat_id, insert_new);
  //const loader = await loadingController.create({});
  //const toast = await toastController.create({ duration: 5000 });
  try {
    //await loader.present();

    if (insert_new && !g_has_already_created_chat_history_record) {

      g_has_already_created_chat_history_record = true;
      if (use_supabase) {
        const updates = {uid: user.id, history: g_user_chat_history};
        let {data, error} = await supabase.from('user_track').insert(updates).select('id').single();
        if (error) throw error;
        g_chat_id = data.id;
      } else {
        let data = await api.userTrackInsert(g_user_chat_history);
        console.log('insert', data)
        if (data.code) throw new Error(data.msg);
        g_chat_id = data.id;
      }
      g_ai_info.chat_id = g_chat_id;
      saveInfoLocal();
      console.log('chat id', g_chat_id);
    } else {
      if (use_supabase) {
        let updates = {history: g_user_chat_history};
        if (ended) updates['ended_at'] = new Date();
        let {error} = await supabase.from('user_track').update(updates, {
          returning: 'minimal',
        }).eq('id', g_chat_id);
        if (error) throw error;
      } else {
        let ended_at = ended ? new Date().getTime() : null;
        console.log('ended at', ended_at)
        let error = await api.userTrackUpdate(g_chat_id, g_user_chat_history, ended_at);
        if (error.code) throw new Error(error.msg);
      }
    }
  } catch (error) {
    console.log(error.message)
    //toast.message = error.message;
    //await toast.present();
  } finally {
    //await loader.dismiss();
  }
}

async function show_the_cool_down_part() {
  let cool_down_sentences = ['慢下来', '做个深呼吸', '静静体会这些感受和需要', '多呆一会儿 (5 分钟或更长时间)', '我陪着你'];
  if (!g_feeling_need_recap) cool_down_sentences[2] = '静静地感受一下你的身体';
  else if (!g_needs_yes.length) cool_down_sentences[2] = '静静体会你的感受';
  else if (!g_feelings_yes.length) cool_down_sentences[2] = '静静体会你的需要';
  show_element_animated(show_cool_down_block);
  if (g_feeling_need_recap) cool_down_sentences = cool_down_sentences.concat(g_feeling_need_recap.split('|'));
  cool_downs.value = cool_down_sentences;

  // reference of scrollToBottom: 
  // https://github.com/ionic-team/ionic-docs/blob/main/static/demos/api/content/index.html
  console.log('cool_down_sentences', cool_down_sentences);
  for (let i = 0; i < cool_down_sentences.length; i++) {
    let duration = debug ? 0 : i <= 7 ? 2 : 5;
    await delay(duration); //slower when showing feelings/needs
    //appendChatMessageComponent(sent);
    show_element_by_id(`cool_down${i}`);
    scroll_to_bottom();
  }
  // play nature sound
  playNatureSound();
  console.log('audio playing', !g_nature_audio.paused);
  if (g_nature_audio.paused) {// if play() failed, let user interact to play
    document.getElementById('natureToggle').checked = false;
    //toggleNatureAudio(false); 
  }
  show_element_by_id('cool_down_nature');
  scroll_to_bottom();
}

// const testRef = ref(null);
// defineExpose({ testRef });

function newChat() {
  if (is_weixin.value) {
    console.log("web_url = " + web_url.value);
    window.location.replace(web_url.value);
  } else {
    window.location.reload();
  }
  //router.replace('/tabs/chat');
  //router.go();
}

async function resumeChat() {
  // if(debug) {
  //   g_ai_leaf = true;
  //   show_more_details();
  //   return;
  // }
  getUserChatLocal();
  getInfoLocal();
  console.log('chat', g_user_chat_history);
  console.log('info', g_ai_info);
  let input = g_user_chat_history.details_input;
  g_chat_id = g_ai_info.chat_id;
  if (!input || !g_chat_id) { // this is a new chat
    chat_greeting();
    return;
  }
  show_greeting.value = false;
  let has_leaf = g_user_chat_history.leaf || g_user_chat_history.leaf_weak;
  leaf_choices.value = g_ai_info.leaf_choices;
  /*if (!has_leaf) {// no leaf yet
    if (!leaf_choices.value) { // no leaf choices available
      user_input_details.value = input;
      user_input_question.value = review_question;
      start_chat_ai();
    } else { // show leaf choices
      show_ai_block.value = true;
      await show_element_sequenced('ai_warmup');
      show_leaf_choices();
    }
    return;
  }
  g_ai_leaf = true;
  if (g_user_chat_history.leaf_weak) ai_weak.value = true;
  g_feelings = g_ai_info.feelings;
  g_needs = g_ai_info.needs;
  if (!g_feelings || !g_needs) { // no leaf info
    if (ai_weak.value) {
      let input = g_user_chat_history.leaf_weak + ' ' + g_user_chat_history.details_input;
      ai_leaf(input); // call our own model to get quick return for feelings
      ai_leaf(input, true); // call open ai model to get more verbose and accurate needs
    } else {
      ai_leaf(g_user_chat_history.leaf);
    }
  } else if (!ai_weak.value) {
    advice.value = g_ai_info.advice_comment;
    if (g_ai_info.fn_of_the_other) {
      g_otherside = g_ai_info.fn_of_the_other;
      if (g_otherside.length && g_otherside[0].length >= 2) otherside.value = g_otherside[0].slice(0, 2);
    }
  }
  if (!g_user_chat_history.scores) { // no start socre
    show_element_animated(show_start_score_block);
    return;
  } */
  if (!g_user_chat_history.details_input.includes('|') && !g_user_chat_history.next) { // missing more details
    show_more_details();
    return;
  } 

  let cur_feeling = g_user_chat_history.cur_feeling;
  if (g_user_chat_history.feelings_yes) g_feelings_yes = [...g_user_chat_history.feelings_yes];
  if ('body_sensation' in g_user_chat_history) {
    has_body_sensation.value = true;
  }
  console.log('g_feelings_yes', g_feelings_yes)

  if (!g_user_chat_history.next || g_user_chat_history.next == 'F') { // feelings
    if (cur_feeling == 'order') {
      get_feeling_order();
      return;
    }
    if (cur_feeling == 'other') {
      if (g_user_chat_history.feeling_other) {
        feeling_other.value = g_user_chat_history.feeling_other.join(' ');
      }
      ask_feeling_other();
      return;
    }
    if (cur_feeling == 'body') {
      show_element_animated(show_body_block);
      return;
    }
    if (cur_feeling == 'body_detail') {
      show_body_detail();
      return;
    }
    if (typeof cur_feeling === 'number' && Number.isInteger(cur_feeling)) {
      g_next_feeling_index = g_user_chat_history.cur_feeling;
    }
    show_feeling();
    return;
  }

  g_prime_feelings = g_feelings_yes.slice(0, 3);
  console.log('prime feelings', g_prime_feelings)
  if ((use_weak_feelings_path || ai_weak.value) && g_user_chat_history.next == 'D') { // leaf_weak, feeling detail
    cur_feeling = g_user_chat_history.cur_feeling;
    console.log('cur_feeling', cur_feeling)
    if (typeof cur_feeling === 'number' && Number.isInteger(cur_feeling)) {
      g_prime_feeling_index = g_user_chat_history.cur_feeling;
    }
    show_body_ex();
    return;
  }
  if (g_user_chat_history.next == 'B') { // body ex or imagine
    body_ex();
    return;
  }
  if (g_user_chat_history.next == 'I') { // body ex imagine
    if (cur_feeling == 'new') {
      feeling_new.value = g_user_chat_history.feeling_new;
      show_imagine_block.value = true;
      show_feeling_new();
      return;
    }
    if (g_user_chat_history.imagine_detail) imagine_detail.value = g_user_chat_history.imagine_detail;
    if (imagine_detail.value) {
      show_imagine_block.value = true;
      show_imagine_detail_block.value = true;
    } else show_imagine();
    return;
  }

  let cur_need = g_user_chat_history.cur_need;
  if (g_user_chat_history.needs_yes) g_needs_yes = [...g_user_chat_history.needs_yes];
  if (g_user_chat_history.next == 'N') { // needs
    let first_need = true;
    if (typeof cur_need === 'number' && Number.isInteger(cur_need)) {
      g_next_need_index = g_user_chat_history.cur_need;
      first_need = false;
    }
    show_next_conversation_of_need(first_need);
    return;
  }
  if (g_user_chat_history.next == 'R') { // recap
    if (g_user_chat_history.cooldown == 'Y') {
      set_recap_sentences();
      show_the_cool_down_part();
    } else show_recap();
    return;
  }
  if (g_user_chat_history.next == 'P') { // positive
    show_positive();
    return;
  }
  if (g_user_chat_history.next == 'O') { // otherside
    await show_element_animated(show_otherside_block);
    let cur_otherside = g_user_chat_history.cur_otherside;
    if (typeof cur_otherside === 'number' && Number.isInteger(cur_otherside)) {
      show_otherside_input.value = true;
      g_otherside_index = cur_otherside;
      await show_next_otherside_prompt();
      let otherside_text = document.getElementById('otherside_text');
      if (otherside_text) otherside_text.classList.remove('invisible');
    } else if (cur_otherside == 'Y') {
      start_otherside_input();
    } else show_otherside();
    return;
  }
  if (g_user_chat_history.next == 'T') { // thoughts
    let thoughts = g_user_chat_history.thoughts ? [...g_user_chat_history.thoughts] : [];
    console.log('thoughts', thoughts)
    let cbt = g_user_chat_history.cur_cbt;
    if (g_ai_info.CBT_examples) thought_examples.value = g_ai_info.CBT_examples;
    // cannot simply use show_next_cbt_question()
    if (typeof cbt === 'number' && Number.isInteger(cbt) && thoughts.length) {
      g_thought_index = cbt; // next thought
      if (cbt >= thoughts.length) {// check validity
        hide_element_by_id('div_cbt');
        next_cbt_general();
        return;
      }
      show_cbt_block.value = true;
      show_thoughts_question.value = true;
      check_thought.value = true;
      let thought = thoughts[cbt];
      if (thought.details.length || thought.correction) { // got err/correction already
        // details has length of 1 or 2, and we have 2 questions before details
        g_cbt_question_index = Math.min(thought.details.length + 2, cbt_questions.length - 1);
        cbt_question.value = cbt_questions[g_cbt_question_index];
        cbt_choice_index.value = 0;
      } else if (thought.cbt_yes) { // got err, ask for correction
        cbt_choice_index.value = 4;
        show_thought_detail.value = true;
        cbt_question.value = `你认为这个想法存在的问题是：\n${thought.cbt_yes}`;
        g_cbt_question_index = 1;
      } else { // no err/correction yet,start from the first question
        g_cbt_question_index = 0;
        cbt_question.value = cbt_questions[g_cbt_question_index];
        cbt_choice_index.value = 2;
      }
      thought_curr.value = `${g_thought_index + 1}：${thought.thought}`;
      show_thought_init(cbt == 0);
    } else if (cbt == 'Y') {
      show_cbt_block.value = true;
      show_thoughts();
      // if (thoughts.length >= 1) thought1.value = thoughts[0].thought;
      // if (thoughts.length >= 2) thought2.value = thoughts[1].thought;
      // if (thoughts.length == 3) thought3.value = thoughts[2].thought;
    } else show_cbt();
    return;
  }
  if (g_user_chat_history.next == 'G') { // cbt general
    let cbt = g_user_chat_history.cur_cbt;
    if (typeof cbt === 'number' && Number.isInteger(cbt)) {
      cbt_general_index = cbt;
    }
    next_cbt_general();
    return;
  }
  if (g_user_chat_history.next == 'L') { // goal
    show_goal();
    return;
  }  
  if (g_user_chat_history.next == 'X') { // exception
    show_exception();
    return;
  } 
  if (g_user_chat_history.next == 'H') { // behavior
    show_behavior();
    return;
  } 
  if (g_user_chat_history.next == 'A') { // advice
    show_advice();
    return;
  }
  if (g_user_chat_history.next == 'S') { // end score
    show_end_score();
    return;
  }
  if (g_user_chat_history.next == 'K') { // feedback
    show_feedback();
    return;
  }
}

onMounted(async () => {
  //if (!store.user.id) router.replace('/');
  await getProfile();

  window.onbeforeunload = function () {
    if (g_user_chat_history.next == 'C') {//complete
      deleteChatLocal();
      return;
    }

    // in case user exits before hitting submission button, save input on screen if any
    let input = user_input_details.value.trim();
    if (input) save_user_input(input);
    input = imagine_detail.value.trim();
    save_feeling_other();
    if (input) g_user_chat_history.imagine_detail = input;
    // input = feeling_new.value;
    // if (input && input.trim()) g_user_chat_history.feeling_new = input;
    // save_thought(thought1);
    // save_thought(thought2);
    // save_thought(thought3);
    saveUserChatLocal();
  }
});
</script>

<style scoped>
p {
  margin-top: 1.5em;
}

.choiceGroup {
  /*margin-left: 10%;*/
  padding: 1em;
}

.pause {
  height: 15px;
}

#recap_choice {
  margin-top: 40px;
}

#body_ex_bg {
  margin: 1px 1%;
  width: 98%;
  max-height: 300px;
}

.endChoice {
  margin-top: 2em;
  font-size: 15px;
}

.feelingSpan {
  margin-right: 5px;
}

.exampleSpan {
  margin-right: 10px;
}

ion-checkbox::part(container) {
  border-radius: 30%;
  border: 2px solid var(--ion-color-primary);
}

.checkItem {
  /* margin-left: 30%; */
  margin: auto 30%;
  font-size: 1.25em;
}

/* .transition {
  opacity: 0;
  transition: opacity 0.8s ease;
} */

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blink 1s linear infinite;
}

.bottom-input {
  width: 100%;
  position: fixed;
  bottom: calc(env(safe-area-inset-bottom) + 75px);
  text-align: right;
}

#messagesWrapper {
  height: 100%;
  overflow-y: scroll;
  padding-bottom: 100px;
  box-sizing: border-box;
}

.v-enter-active,
.v-leave-active {
  transition: opacity 1s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}

</style>
