<template>
  <ion-app>
    <ion-router-outlet/>
  </ion-app>
</template>

<script setup>
import {IonApp, IonRouterOutlet, useIonRouter} from '@ionic/vue';
import {store} from './store';
import {api} from './api';
import {supabase} from './supabase';
import {session, use_supabase, is_weixin} from '@/utils';

const router = useIonRouter();
if (use_supabase) store.user = supabase.auth.user() ?? {};
else store.user = api.user() ?? {};
console.log('store.user:', store.user);
console.log('use_supabase:', use_supabase);

if (use_supabase) {
  supabase.auth.onAuthStateChange((_, _session) => {
    // console.log("passed in session", _session?.user?.id)
    // console.log("current session", session.value?.user?.id)

    // auth.onAuthStateChanged triggers on browser tab switch
    // https://github.com/supabase/gotrue-js/issues/284
    if (_session?.user?.id == session.value?.user?.id) return;

    console.log('app session', _session)
    session.value = _session;
    store.user = _session?.user ?? {};
    console.log('auth change store.user:', store.user);
    let next = store.user.id ? '/tabs/chat' : '/';
    router.replace(next);
    window.location.replace(next); // in case router.replace does not work (confirm link redirect)
  })
} else {

  if (window._wcprms?.code) {
    console.log('微信小程序环境', window._wcprms?.code);
    is_weixin.value = true;
    //always show chat page first in wechat
    router.replace('/tabs/chat');
  } else {
    console.log('api onAuthStateChange:');
    api.onAuthStateChange((_, _session) => {
      console.log('app session', _session)
      session.value = _session;
      store.user = _session?.user ?? {};

      let next = store.user.uid ? '/tabs/chat' : '/';
      router.replace(next);
    });

  }
}

</script>
