<template>
  <ion-page>
    <ion-content>
      <ion-tabs>
        <ion-router-outlet></ion-router-outlet>
        <ion-tab-bar>
          <ion-tab-button tab="chat" href="/tabs/chat">
            <!-- <ion-icon :icon="chatbubbleEllipses" /> -->
            <ion-icon class="icon-default" src="/assets/icon/chat.svg"></ion-icon>
            <ion-icon class="icon-selected" src="/assets/icon/chat-selected.svg"></ion-icon>
            <ion-label>{{ t('chat') }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="feedback" href="/tabs/feedback">
            <!-- <ion-icon :icon="paperPlane" /> -->
            <ion-icon class="icon-default" src="/assets/icon/feedback.svg"></ion-icon>
            <ion-icon class="icon-selected" src="/assets/icon/feedback-selected.svg"></ion-icon>
            <ion-label>{{ t('feedback') }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="resource" href="/tabs/resource">
            <!-- <ion-icon :icon="heart" /> -->
            <ion-icon class="icon-default" src="/assets/icon/resource.svg"></ion-icon>
            <ion-icon class="icon-selected" src="/assets/icon/resource-selected.svg"></ion-icon>
            <ion-label>{{ t('resource') }}</ion-label>
          </ion-tab-button>

          <ion-tab-button tab="setting" href="/tabs/setting">
            <!-- <ion-icon :icon="settings" /> -->
            <ion-icon class="icon-default" src="/assets/icon/setting.svg"></ion-icon>
            <ion-icon class="icon-selected" src="/assets/icon/setting-selected.svg"></ion-icon>
            <ion-label>{{ t('setting') }}</ion-label>
          </ion-tab-button>

        </ion-tab-bar>
      </ion-tabs>
    </ion-content>
  </ion-page>
</template>

<script setup>
import {
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonContent,
  IonLabel,
  IonIcon,
  IonPage,
  IonRouterOutlet,
} from '@ionic/vue';
//import { settings, heart, paperPlane} from 'ionicons/icons';

// localization
import {useI18n} from 'vue-i18n';

const {t} = useI18n();

// const tabMode = 'md';
//
// const isInIos = navigator.userAgent.includes('iPhone');
//
// const tabBarStyle = {'marginBottom': isInIos ? '2em' : '1em'}


</script>

<style scoped>
ion-label {
  font-size: 16px;
}

/*ion-router-outlet{*/
/*  padding-bottom: 10em;*/
/*}*/

ion-tab-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  margin-left: 1em;
  margin-right: 1em;
  margin-bottom: 1em;
  /*border-radius: 25px;*/
  border: none;
  background: none;
}

ion-tab-button {
  /*border: 1px solid #EBF0ED;*/
  background-color: rgba(255, 255, 255, 0.66);
  backdrop-filter: blur(5px);

}
ion-tab-button:first-child{
  border-radius: 25px 0 0 25px;
}
ion-tab-button:last-child {
  border-radius: 0 25px 25px 0;
}

/* Hide the selected icon by default */
.icon-selected {
  font-size: 16px;
  display: none;
}

.icon-default{
  font-size: 16px;
}

/* Show the selected icon and hide the default icon when the tab button is selected/hovered */
ion-tab-button[aria-selected="true"] .icon-selected,
ion-tab-button:hover .icon-selected,
ion-tab-button.tab-selected .icon-selected {
  display: inline;
}

ion-tab-button[aria-selected="true"] .icon-default,
ion-tab-button:hover .icon-default,
ion-tab-button.tab-selected .icon-default {
  display: none;
}

</style>