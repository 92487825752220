import {ref} from "vue";
import {api} from '@/api';
import {supabase} from '@/supabase';
import {store} from '@/store';
import {toastController, loadingController,} from '@ionic/vue';

export const is_weixin = ref(false);
export const use_supabase = false;
export const user_alias = ref('');
export const gender = ref('');
export const age = ref('');
export const profession = ref('');
export const nature = ref('');
export const session = use_supabase ? ref(supabase.auth.session()) : ref(api.session());
export const amount = ref((session.user?.horns_green||0) + (session.user?.horns_red||0));
export const profile_saved = ref(true);
export let web_url = ref('');
export let feeling_list = ['生气', '难过', '惊讶', '受伤', '烦躁', '受挫', '沮丧', '孤单', '害怕', '尴尬', '焦虑', '紧张', '悲伤', '内疚', '遗憾', '懊恼', '无助', '无奈', '无力', '后悔', '着急', '羞愧', '委屈', '失落', '失望', '心疼', '不舍', '惋惜'];
export let feeling_list_en = ['Angry', 'Sad', 'Surprised', 'Hurt', 'Irritated', 'Frustrated', 'Depressed', 'Lonely', 'Afraid', 'Embarrassed', 'Anxious', 'Nervous', 'Grief-stricken', 'Guilty', 'Regretful', 'Fretful', 'Helpless', 
'Resigned', 'Powerless', 'Remorseful', 'Worried', 'Ashamed', 'Lost', 'Disappointed', 'Heartbroken', 'Reluctant to part', 'Rueful'];


export let need_list = ['爱', '美', '接纳', '意义', '自由', '信任', '欣赏', '体贴', '成长', '同理', '信心', '尊重', '尊严', '支持', '理解', '和谐', '鼓励', '秩序', '平静', '真实', '娱乐', '公平', '关注', '陪伴', '认同', '肯定', '重视', '归属感', '安全感', '自我价值', '亲密关系'];

export let need_list_en = ['Love', 'Beauty', 'Acceptance', 'Meaning', 'Freedom', 'Trust', 'Appreciation', 'Consideration', 'Growth', 'Empathy', 'Confidence', 'Respect', 'Dignity', 'Support', 'Understanding', 'Harmony', 'Encouragement', 'Order', 'Tranquility', 'Authenticity', 'Entertainment', 'Fairness', 'Attention', 'Companionship', 'Identification', 'Affirmation', 'Importance', 'Sense of belonging', 'Sense of security', 'Self-worth', 'Intimate relationships'];

export let g_user_chat_history = {
    //leaf: '', //leaf_weak:'',
    //scores: [],
    //details_input: '',
    //feelings_yes: [], feeling_detail: {}, body_sensation: '', feeling_other: [], imagine_detail: '', feeling_new: '',
    //needs_yes: [], need_detail: [],
    //otherside: {},
    //thoughts: [], cbt_general: [],
    //angle_new: '',
    //feedback: [],
};

export function saveUserChatLocal() {
    localStorage.setItem(`${store.user.id}_chat`, JSON.stringify(g_user_chat_history));
}

export function getUserChatLocal() {
    let chat = localStorage.getItem(`${store.user.id}_chat`);
    if (chat) g_user_chat_history = JSON.parse(chat);
}

export let g_ai_info = {
    //chat_id: '', // needed for api.ai_req
    //leaf_choices: [],
    //feelings:[],
    //needs:[],
    //CBT_examples:[],
    //fn_of_the_other:[],
    //advice_comment:[]
};

export function saveInfoLocal() {
    localStorage.setItem(`${store.user.id}_info`, JSON.stringify(g_ai_info));
}

export function getInfoLocal() {
    let info = localStorage.getItem(`${store.user.id}_info`);
    if (info) g_ai_info = JSON.parse(info);
}

export function deleteChatLocal() {
    localStorage.removeItem(`${store.user.id}_chat`);
    localStorage.removeItem(`${store.user.id}_info`);
}

export function show_element_by_id(id, style = 'block') {
    let el = document.getElementById(id);
    if (el) el.style.display = style;
}

export function hide_element_by_id(id) {
    let el = document.getElementById(id);
    if (el) el.style.display = "none";
}

export function delay(seconds) {
    return new Promise(function (resolve) {
        setTimeout(resolve, 1000 * seconds);
    });
}

export async function show_element_animated(el) {
    if (el.value) {
        el.value = false;
        await delay(0.8);
        el.value = true;
    } else {
        el.value = true;
    }
}

export async function blink_element(id, duration) {
  let el = id instanceof Element? id : document.getElementById(id);
  console.log('blink', id)
  if (el) {
    el.classList.add("blink");
    if (duration){
      await delay(duration);
      unblink_element(id);
    }
  }
}

export function unblink_element(id) {
   let el = id instanceof Element? id : document.getElementById(id);
   if (el) el.classList.remove("blink");
}

export function scroll_to_bottom(duration = 1500) {
    document.getElementById('pageContent').scrollToBottom(duration);
}

let g_meditation_audio;

export function loadAudio() {
    if (g_meditation_audio) return;

    g_meditation_audio = new Audio('/assets/audio/bodyExEllen.mp3');

    g_meditation_audio.onended = function () {
        //document.getElementById('ex_audio_btn').innerHTML = '播放';
        document.getElementById('ex_audio_btn').innerHTML = 'Play';
        let el = document.getElementById('body_ex_next');
        if (el) el.innerHTML = '下一步';
    };
}

export function toggleAudio() {
    let btn = document.getElementById('ex_audio_btn');
    if (g_meditation_audio.paused) {
        g_meditation_audio.play();
        //btn.innerHTML = '暂停';
        btn.innerHTML = 'Pause';
    } else {
        g_meditation_audio.pause();
        //btn.innerHTML = '播放';
        btn.innerHTML = 'Play';
    }
}

export function stopAudio() {
    if (g_meditation_audio) {
        g_meditation_audio.pause();
        g_meditation_audio.currentTime = 0;
    }
}

export const updateProfile = async (options) => {
    const loader = await loadingController.create({});
    const toast = await toastController.create({duration: 5000});
    try {
        await loader.present();

        if (use_supabase) {
            let updates = {id: store.user.id, updated_at: new Date(), ...options};
            console.log("updates", updates);
            let {error} = await supabase.from('profiles').upsert(updates, {
                returning: 'minimal', // Don't return the value after inserting
            });
            if (error) throw error;
        } else {
            let {error} = await api.UserInfoUpdate(options);
            if (error) throw error;
        }
        profile_saved.value = true;
    } catch (error) {
        toast.message = error.message || error.msg;
        await toast.present();
    } finally {
        await loader.dismiss();
    }
}


export const isInWxmp = () => {
    return window.__wxjs_environment === 'miniprogram';
}

// 加载图片
export const loadImage = async (params) => {
    // 图片src 必传
    let {src} = params;
    return new Promise((resolve, reject) => {
        let img = new Image()
        img.src = src
        img.crossOrigin = 'anonymous'
        img.onload = () => {
            resolve(img)
        }
        img.onerror = reject
    })
}

// 图片转base64
export const img2Base64 = (image) => {
    // 图片画到canvas
    const canvas = document.createElement('canvas');
    canvas.width = image.width;
    canvas.height = image.height;
    const context = canvas.getContext('2d');
    context.drawImage(image, 0, 0);
    return canvas.toDataURL('image/png', 1.0)
}

// base64转blob
export const base64ToBlob = (base64Code) => {
    let parts = base64Code.split(';base64,')
    let contentType = parts[0].split(':')[1]
    let raw = window.atob(parts[1])
    let rawLength = raw.length
    let uInt8Array = new Uint8Array(rawLength)
    for (let i = 0; i < rawLength; ++i) {
        uInt8Array[i] = raw.charCodeAt(i)
    }
    return new Blob([uInt8Array], {
        type: contentType
    })
}

// 下载资源
export const downloadFile = (href, filename) => {
    const link = document.createElement('a');
    link.href = href;
    link.download = filename;
    link.setAttribute('type', 'application/octet-stream');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
