<template>
  <div>
    <div class='chatDiv'>
      <div class="chat" :class="{ 'fromUser': props.fromUser }"><slot /></div>
    </div>
  </div>
</template>

<script setup>
  const props = defineProps(["fromUser",]);
</script>

<style scoped>
.chatDiv{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 16px;
  color: var(--ion-color-primary);
}
.chat {
  white-space: pre-wrap; 
  position:relative;
  background: white;
  padding: 8px 10px;
  font-size: 1em !important;
  line-height: 1.4em;
  /*margin-left: 2% !important;*/
  /* max-width: 90% !important; */
  display: inline-block;
  /* border-radius: 1em; */
  border-radius: 0 0.5em 0.5em 0.5em;
}

.chat.fromUser {
  background: var(--ion-color-primary);
  color: white;
  align-self: flex-end;
  margin-right: 2% !important;
  border-radius: 0.5em 0 0.5em 0.5em;
}

/* .chat::after {
  content: "";
  border: 6px solid transparent;
  position: absolute; 
  border-right-color: white;   
  border-left: 0;
  left: -6px; top: 50%; 
  margin-top: -6px;
}

.chat.fromUser::after {
  content: "";
  border: 6px solid transparent;
  position: absolute;
  border-left-color: var(--ion-color-primary);
  border-right: 0;
  left: calc(100% - 1px);
  top: 50%;
  margin-top: -6px;
} */
 
/*ion-avatar {*/
/*  margin-right: 8px;*/
/*  max-width: 25px;*/
/*  max-height: 25px;*/
/*}*/
 
</style>