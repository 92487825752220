<template>
  <ion-item class="transparent">
    <ion-label position="stacked" class="ion-text-wrap"><slot /></ion-label>
    <ion-input type="text"
      :value="props.modelValue"
      @ionInput="$emit('update:modelValue', $event.target.value)">
    </ion-input>
  </ion-item>
</template>

<script setup>
  import {IonItem, IonLabel, IonInput} from "@ionic/vue";
  const props = defineProps(["modelValue",]);
</script>