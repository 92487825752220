import {createRouter, createWebHistory} from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';
import LoginPage from '../views/LoginPage.vue';
import RegisterPage from '../views/RegisterPage.vue';
// import AccountPage from '../views/Account.vue';
import ChatPage from '../views/ChatPage.vue';
import TabPage from '../views/TabPage.vue';

const routes = [
    {
        path: '/',
        name: 'Login',
        component: LoginPage
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterPage
    },
    {
        path: '/body',
        name: 'Body',
        component: () => import('@/views/BodyExercise.vue'),
    },
    {
        path: '/feeling',
        name: 'Feeling',
        component: () => import('@/views/FeelingList.vue'),
    },
    {
        path: '/need',
        name: 'Need',
        component: () => import('@/views/NeedList.vue'),
    },
    {
        path: '/cbt',
        name: 'cbt',
        component: () => import('@/views/CBTExample.vue'),
    },
    {
        path: '/feelingdetail',
        name: 'FeelingDetail',
        component: () => import('@/views/FeelingExample.vue'),
    },
    {
        path: '/review',
        name: 'Review',
        component: () => import('@/views/ChatReview.vue'),
    },
    {
        path: '/hotline',
        name: 'HotLine',
        component: () => import('@/views/HotLine.vue'),
    },
    // {
    //   path: '/account',
    //   name: 'Account',
    //   component: AccountPage
    // },
    // {
    //   path: '/chat',
    //   name: 'Chat',
    //   component: ChatPage
    // },
    {
        path: '/tabs/',
        component: TabPage,
        children: [
            {
                path: '',
                redirect: 'chat',
            },
            {
                path: 'chat',
                //component: () => import('@/views/ChatPage.vue'),
                component: ChatPage,
            },
            {
                path: 'resource',
                component: () => import('@/views/ResourceList.vue'),
            },
            {
                path: 'feedback',
                component: () => import('@/views/FeedbackPage.vue'),
            },
            {
                path: 'setting',
                component: () => import('@/views/setting/'),
            },
        ]
    },
    {
        path: '/recharge',
        component: () => import('@/views/setting/ReCharge.vue'),
    },
    {
        path: '/recharge/result',
        component: () => import('@/views/setting/ReChargeResult.vue'),
    },
    {
        path: '/recharge/list',
        component: () => import('@/views/setting/ReChargeList.vue'),
    },
    {
        path: '/profile',
        component: () => import('@/views/setting/Profile.vue'),
    },
    {
        path: '/setProfile',
        component: () => import('@/views/setting/SetProfile.vue'),
    },
    {
        path: '/transfer',
        component: () => import('@/views/setting/Transfer.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

// router.beforeEach((to, from, next) => {
//   console.log('URL changed from', from.fullPath, 'to', to.fullPath);
//   next();
// });

// router.afterEach((to, from) => {
//   console.log('URL changed from', from.fullPath, 'to', to.fullPath);
// });

export default router
