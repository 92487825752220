<template>
<base-layout :page-title="pageTitle">
  <div class="ion-padding">
    <!-- <p><ion-label><h1>{{ t('registerTitle') }}</h1></ion-label></p> -->
    <ChatMessage>{{ t('registerTitle') }}</ChatMessage>
  </div>
  <div class="ion-padding">
    <form @submit.prevent="handleRegister">
      <ion-item>
        <ion-label position="stacked">{{ t('email') }}</ion-label>
        <ion-input v-model="email" type="email" name="email" autocomplete required></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ t('password') }}</ion-label>
        <ion-input v-model="password" type="password" name="password" required></ion-input>
      </ion-item>
      <ion-item>
        <ion-label position="stacked">{{ t('confirmPassword') }}</ion-label>
        <ion-input v-model="confirmPassword" type="password" required></ion-input>
      </ion-item>
      <div class="ion-text-center" id="divSignup">
        <ion-button type="submit" class="navBtn" shape='round'>{{ t('signup') }}</ion-button>
      </div>
    </form>
  </div>
</base-layout>
</template>

<script setup>
import { api } from '../api';
import { supabase } from '../supabase';
import {
  useIonRouter,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController,
  loadingController,
} from '@ionic/vue';
import ChatMessage from '@/components/ChatMessage.vue';
import { ref } from 'vue';
import { use_supabase, hide_element_by_id } from '../utils';
// localization
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const pageTitle = t('signup');

const router = useIonRouter();
const user_in = use_supabase? supabase.auth.user() : api.user();
if (user_in) {
    router.replace('/tabs/chat');
}

const email = ref('');
const password = ref('');
const confirmPassword = ref('');

const handleRegister = async () => {
  const loader = await loadingController.create({});
  const toast = await toastController.create({ duration: 5000,  message: t('emailConfirmMsg')});
  try {
    //
    if (password.value !== confirmPassword.value) {
        toast.message = t('pwdNoMatch');
        return;
    }
    if (use_supabase){
      const { error } = await supabase.auth.signUp({
        email: email.value,
        password: password.value,
      });
      if (error) throw error;
    }
    else{
      const error = await api.signUp({
        email: email.value,
        password: password.value,
      });
      if (error.code) throw error;
    }
  } catch (error) {
    toast.message = error.error_description || error.message || error.msg;
  } finally {
    let success = toast.message == t('emailConfirmMsg');
    if (success) {
      hide_element_by_id('divSignup');
    }
    await toast.present();
    await loader.dismiss();
  }
};
</script>

<style scoped>
ion-label{
  height: 50px;
  font-size: 1.5em !important;
}
ion-button{
  font-size: 1.2em !important;
  margin-top: 1em;
}
ion-item{
  --background: transparent;
}
</style>
