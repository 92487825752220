<template>
<base-layout :page-title="pageTitle">
<div id='login-page' class='hidden'>
  <div class="ion-padding">
   <!-- <p><ion-label color="secondary"><h1>{{ t('loginTitle') }}</h1></ion-label></p> -->
   <ChatMessage>{{ t('loginTitle') }}</ChatMessage>
  </div>
  <div class="ion-padding ion-text-center">
    <form @submit.prevent="handleLogin">
      <ion-item>
        <ion-label position="stacked">{{ t('email') }}</ion-label>
        <ion-input
          v-model="email"
          type="email"
          name="email"
          autocomplete
        ></ion-input>
      </ion-item>
       <ion-item>
        <ion-label position="stacked">{{ t('password') }}</ion-label>
        <ion-input
          v-model="password"
          type="password"
          name="password"
          required
        ></ion-input>
      </ion-item>
      <div class="ion-text-center">
        <ion-button type="submit" class="navBtn" shape='round'>{{ t('login') }}</ion-button>
      </div>

      <div class='ion-text-center' id="divSignUp">
      <ion-label><h2>{{ t('noAccount') }}
      <router-link :to="{name:'Register'}">{{ t('signup') }}</router-link>
      </h2></ion-label>
      </div>
    </form>
  </div>
</div>
</base-layout>
</template>

<script setup>
import { api } from '../api';
import { supabase } from '../supabase';
import {
  useIonRouter,
  IonItem,
  IonLabel,
  IonInput,
  IonButton,
  toastController,
  loadingController,
} from '@ionic/vue';
import ChatMessage from '@/components/ChatMessage.vue';
import { ref, onMounted } from 'vue';
import { use_supabase } from '../utils';

// localization
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
const pageTitle = t('login');

const router = useIonRouter();
const email = ref('');
const password = ref('');

const handleLogin = async () => {
  const loader = await loadingController.create({});
  const toast = await toastController.create({ duration: 5000 });

  try {
    await loader.present();
    if (use_supabase){
      const { error } = await supabase.auth.signIn({
            email: email.value,
            password: password.value,
      });
      if (error) throw error;
    }
    else{
      const error = await api.signIn({
          email: email.value,
          password: password.value,
      });
      if (error.code) throw error;
    }
    //router.replace('/tabs/chat');
  } catch (error) {
    toast.message = error.error_description || error.message || error.msg;
    await toast.present();
  } finally {
    await loader.dismiss();
  }
};

onMounted(() => {
  const user_in = use_supabase? supabase.auth.user() : api.user();
  if (user_in) {
    router.replace('/tabs/chat');
  } 
  else{
    let el = document.getElementById("login-page");
    if (el) el.classList.remove("hidden");
  }
});

</script>

<style scoped>
.hidden {
  display: none;
}
ion-label{
  height: 50px;
  font-size: 1.5em !important;
}
ion-button{
  font-size: 1.2em !important;
  margin-top: 1em;
}
#divSignUp{
  margin-top: 3em;
}
ion-item{
  --background: transparent !important;
}
</style>
